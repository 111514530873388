import { Image, StyleSheet } from '@react-pdf/renderer'
import moment from 'moment-timezone'
import { Row } from '../Pane'
import { Text } from '../Text'
import { BOL_DEFAULT_BORDER, BOL_DEFAULT_MARGIN, BOL_DEFAULT_PADDING } from './BillOfLadingDefaults'

interface SignatureLineProps {
  date?: string
  label: string
  signaturePlainText?: string | null
  signatureText?: string
  signatureUrl?: string
  timezone: string

  showTrailerNumber?: boolean
}

const styles = StyleSheet.create({
  shipperSignature: {
    position: 'absolute',
    top: -20,
    left: 90,
    height: 'auto',
    width: '10%',
    zIndex: -1,
  },
  driverSignature: {
    fontSize: 20,
    fontFamily: 'Signature',
    lineHeight: 0.1,
    top: -10,
    zIndex: 1,
  },
  signaturePlainText: {
    fontSize: 8,
    fontFamily: 'Helvetica Neue',
    lineHeight: 0.1,
    zIndex: 1,
    left: 15,
  },
})

export const BillOfLadingSignatureLine = ({
  date,
  label,
  showTrailerNumber,
  signaturePlainText,
  signatureText,
  signatureUrl,
  timezone,
}: SignatureLineProps): JSX.Element => {
  return (
    <Row borderBottom={BOL_DEFAULT_BORDER} paddingBottom={2} marginTop={BOL_DEFAULT_MARGIN * 2} wrap={false} zIndex={1}>
      <Text bold flex={1}>
        {label}
      </Text>
      {signatureUrl && <Image src={signatureUrl} style={{ ...styles.shipperSignature }} />}
      {signatureUrl && signaturePlainText ? (
        <Text {...styles.signaturePlainText} flex={1}>
          {signaturePlainText}
        </Text>
      ) : (
        <></>
      )}
      {!signatureUrl && signatureText ? (
        <Text {...styles.driverSignature} flex={1}>
          {signatureText}
        </Text>
      ) : (
        <></>
      )}
      <Row width={130}>
        <Text bold paddingRight={BOL_DEFAULT_PADDING}>
          Date:
        </Text>
        <Text>{date && moment(date).tz(timezone).format('  MM/DD/YY h:mma')}</Text>
      </Row>
      <Row width={170}>
        <Text bold paddingRight={BOL_DEFAULT_PADDING} textAlign="right" width={80}>
          {showTrailerNumber ? 'Trailer No:' : 'Seal No:'}
        </Text>
      </Row>
    </Row>
  )
}
