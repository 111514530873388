import { Page, StyleSheet, View } from '@react-pdf/renderer'
import moment from 'moment-timezone'
import { MothershipLogo } from '../components/MothershipLogo'
import { Column, Row } from '../components/Pane'
import { PDFWrapper } from '../components/PDFWrapper'
import { Text } from '../components/Text'
import { centsToCurrencyString } from '../utils/currency'
import { DocumentType } from './DocumentType'

const DEFAULT_PADDING = 5
const DEFAULT_MARGIN = DEFAULT_PADDING * 2
const DEFAULT_TABLE_BORDER = '1pt solid #bdbdbd'
const DEFAULT_TABLE_BORDER_RADIUS = '3pt'
const DATE_FORMAT = 'MM/DD/YYYY'
const DELIVERY_DATE_FORMAT = 'MM/DD'

const styles = StyleSheet.create({
  page: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 20,
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontSize: 8,
  },
})

export interface Address {
  name: string
  streetAddress1: string
  streetAddress2?: string
  city: string
  state: string
  zip: string
}

export interface PayoutParams {
  referenceNumber: string | null
  completedAt: string | null
  description: string
  pickupLocation: Address | null
  deliveryLocation: Address | null
  payoutAmountCents: number
}

export interface CarrierEarnings {
  totalEarnedCents: number
  name: string
  payouts: Array<PayoutParams>
}

export interface CarrierEarningReportParams {
  companyName: string
  carriers: { [name: string]: CarrierEarnings }
  totalEarnedCents: number
  periodStartAt: string
  periodEndAt: string
}

const toDollars = (val: number): string => {
  return centsToCurrencyString(val)
}

const EarningTable = (props: CarrierEarnings): JSX.Element => {
  return (
    <View>
      <Row marginTop={DEFAULT_MARGIN}>
        <Text fontSize={16} flex={1}>
          {props.name}
        </Text>
      </Row>
      <Row
        marginTop={DEFAULT_MARGIN}
        backgroundColor={'#eaeaea'}
        border={DEFAULT_TABLE_BORDER}
        borderTopLeftRadius={DEFAULT_TABLE_BORDER_RADIUS}
        borderTopRightRadius={DEFAULT_TABLE_BORDER_RADIUS}
        padding={'3pt'}
      >
        <Text flex={1}>Reference #</Text>
        <Text flex={1}>Delivered</Text>
        <Text flex={2}>Description</Text>
        <Text flex={2}>Pickup Location</Text>
        <Text flex={2}>Delivery Location</Text>
        <Text flex={1}>Payout</Text>
      </Row>
      {props.payouts.map((val, idx) => (
        <Row
          key={`${val.referenceNumber}${idx}`}
          padding={'3pt'}
          wrap={false}
          backgroundColor={idx % 2 === 0 ? '#ffffff' : '#eaeaea'}
          borderRight={DEFAULT_TABLE_BORDER}
          borderLeft={DEFAULT_TABLE_BORDER}
          borderBottomLeftRadius={idx === props.payouts.length - 1 ? DEFAULT_TABLE_BORDER_RADIUS : '0pt'}
          borderBottomRightRadius={idx === props.payouts.length - 1 ? DEFAULT_TABLE_BORDER_RADIUS : '0pt'}
          borderBottom={idx === props.payouts.length - 1 ? DEFAULT_TABLE_BORDER : ''}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Text flex={1}>{val.referenceNumber ? val.referenceNumber : 'n/a'}</Text>
          <Text flex={1}>{val.completedAt ? moment(val.completedAt).format(DELIVERY_DATE_FORMAT) : 'n/a'}</Text>
          <Text flex={2}>{val.description}</Text>
          <Column flex={2}>
            {val.pickupLocation ? (
              <>
                <Text>{val.pickupLocation.name}</Text>
                <Text>{val.pickupLocation.streetAddress1}</Text>
                {val.pickupLocation.streetAddress2 ? <Text>{val.pickupLocation.streetAddress2}</Text> : null}
                <Text>{`${val.pickupLocation.city}, ${val.pickupLocation.state} ${val.pickupLocation.zip}`}</Text>
              </>
            ) : (
              <Text>{'n/a'}</Text>
            )}
          </Column>
          <Column flex={2}>
            {val.deliveryLocation ? (
              <>
                <Text>{val.deliveryLocation.name}</Text>
                <Text>{val.deliveryLocation.streetAddress1}</Text>
                {val.deliveryLocation.streetAddress2 ? <Text>{val.deliveryLocation.streetAddress2}</Text> : null}
                <Text>{`${val.deliveryLocation.city}, ${val.deliveryLocation.state} ${val.deliveryLocation.zip}`}</Text>
              </>
            ) : (
              <Text>{'n/a'}</Text>
            )}
          </Column>
          <Text flex={1} bold>
            {toDollars(val.payoutAmountCents)}
          </Text>
        </Row>
      ))}
      <Row marginTop={DEFAULT_MARGIN}>
        <Text bold>Total: </Text>
        <Text>{toDollars(props.totalEarnedCents)}</Text>
      </Row>
    </View>
  )
}

export const CarrierEarningReport: DocumentType<CarrierEarningReportParams> = ({
  renderValues,
}: {
  renderValues: CarrierEarningReportParams
}): JSX.Element => {
  const startAt = moment(renderValues.periodStartAt).format(DATE_FORMAT)
  const endAt = moment(renderValues.periodEndAt).format(DATE_FORMAT)
  return (
    <PDFWrapper>
      <Page size="A4" style={styles.page}>
        <Header
          companyName={renderValues.companyName}
          dateRange={`${startAt} - ${endAt}`}
          supportEmail={'support@mothership.com'}
          supportNumber={'(310) 905-8677'}
          totalEarningsCents={renderValues.totalEarnedCents}
        />
        {Object.values(renderValues.carriers).map((earnings: CarrierEarnings) => {
          return <EarningTable key={earnings.name} {...earnings} />
        })}
      </Page>
    </PDFWrapper>
  )
}

export interface HeaderProps {
  supportNumber: string
  supportEmail: string
  companyName: string
  dateRange: string
  totalEarningsCents: number
}

const Header = (props: HeaderProps): JSX.Element => {
  return (
    <Row>
      <Column flex={'0 0 20vw'}>
        <MothershipLogo />
      </Column>
      <Column flex={1} alignItems="center">
        <Text fontSize={20} bold>
          Earnings Report
        </Text>
        <Text fontSize={14} color={'#4a4a4a'}>
          {props.companyName}
        </Text>
        <Text fontSize={14} color={'#4a4a4a'}>
          {props.dateRange}
        </Text>
        <Text fontSize={14} color={'#4a4a4a'}>
          Total Earnings: ${(props.totalEarningsCents / 100).toFixed(2)}
        </Text>
      </Column>
      <Column flex={'0 0 20vw'} alignItems="flex-end" paddingRight={10} color={'#4a4a4a'}>
        <Text fontSize={10} bold>
          Need Help?
        </Text>
        <Text fontSize={8}>Call or text our support line</Text>
        <Text fontSize={8}>{props.supportNumber}</Text>
        <Text fontSize={8}>{props.supportEmail}</Text>
      </Column>
    </Row>
  )
}
