import { Image, StyleSheet } from '@react-pdf/renderer'
import moment from 'moment-timezone'
import { Row } from '../Pane'
import { Text } from '../Text'

/*
  NOTE [jdao] As of Feb 2023, this component is not used. It existed in form-api (Kathie), but
  was broken for a while, so we migrated the react element, but decided to not use it in the BOL.
*/

/** This file no longer exists, and was kept only for historical purposes. */
const DELIVERY_STAMP = 'https://s3-us-west-1.amazonaws.com/billy-form-gen/assets/proofOfDeliveryStamp.png'

interface BillOfLadingPODStampProps {
  completedAt: string
  timezone: string
}

export const BillOfLadingPODStamp = (props: BillOfLadingPODStampProps): JSX.Element => {
  const { completedAt, timezone } = props
  return (
    <Row {...styles.extendedPODStamp}>
      <Row {...styles.extendedPODImageContainer}>
        <Image src={DELIVERY_STAMP} style={{ ...styles.extendedPODImage }} />
        <Text {...styles.extendedPODDateStamp}>{moment(completedAt).tz(timezone).format('M/D/YY')}</Text>
      </Row>
    </Row>
  )
}

const styles = StyleSheet.create({
  // Extended POD Stamp
  extendedPODStamp: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 160,
    marginTop: 10,
  },
  extendedPODImageContainer: {
    position: 'relative',
    height: '100%',
  },
  extendedPODImage: {
    // position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
  },
  extendedPODDateStamp: {
    // position: 'absolute',
    fontFamily: 'Courier',
    fontSize: 7,
    top: 88,
    right: 48,
  },
})
