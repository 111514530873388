import { XPODeliveryReceiptDocumentGenerationData } from '@mothership/document-types'
import { Page, StyleSheet, View } from '@react-pdf/renderer'
import { PageSize } from '@react-pdf/types'
import { PDFWrapper } from '../components/PDFWrapper'
import {
  XPODeliveryReceiptCommoditiesAndRemarks,
  XPODeliveryReceiptCommoditiesAndRemarksHeader,
} from '../components/XPODeliveryReceiptCommoditiesAndRemarks'
import { XPODeliveryReceiptFooter } from '../components/XPODeliveryReceiptFooter'
import { XPODeliveryReceiptHeader } from '../components/XPODeliveryReceiptHeader'
import { XPODeliveryReceiptService } from '../components/XPODeliveryReceiptService'
import { XPODeliveryReceiptShipperNumbers } from '../components/XPODeliveryReceiptShipperNumbers'
import { XPODeliveryReceiptStakeholders } from '../components/XPODeliveryReceiptStakeholders'
import { XPODeliveryReceiptTable } from '../components/XPODeliveryReceiptTable'
import { Fonts } from '../style'
import { DocumentType } from './DocumentType'

const PAGE_SIZE: PageSize = [595, 340]
const BORDER = '1px solid #000'

export const XPODeliveryReceipt: DocumentType<XPODeliveryReceiptDocumentGenerationData> = (props: {
  renderValues: XPODeliveryReceiptDocumentGenerationData
}): JSX.Element => {
  const {
    renderValues: {
      proNumber,
      service,
      stakeholders,
      shipperNumbers,
      commodities,
      accessorials,
      totalPieces,
      totalChargeText2,
      totalWeightLbs,
      poNumbers,
      otherReferenceNumbers,
      delivery,
      remarks,
      notification,
    },
  } = props

  const remarksArray = (Object.values(remarks ?? {}) ?? []) as string[]

  return (
    <PDFWrapper>
      <Page size={PAGE_SIZE} style={styles.page}>
        <View
          style={{
            position: 'absolute',
            top: 14,
            left: 14,
            width: '100%',
            height: '100%',
            border: BORDER,
          }}
          fixed={true}
        >
          <XPODeliveryReceiptTable />
        </View>
        <XPODeliveryReceiptHeader proNumber={proNumber} />
        <XPODeliveryReceiptService {...service} />
        <XPODeliveryReceiptStakeholders {...stakeholders} notification={notification} />
        <XPODeliveryReceiptShipperNumbers shipperNumbers={shipperNumbers} />
        <XPODeliveryReceiptCommoditiesAndRemarksHeader />
        <View style={{ borderRight: BORDER, borderLeft: BORDER }}>
          <XPODeliveryReceiptCommoditiesAndRemarks
            commodities={commodities}
            accessorials={accessorials}
            totalPieces={totalPieces}
            finalCharges={totalChargeText2}
            totalWeight={totalWeightLbs}
            poNumbers={poNumbers}
            otherReferenceNumbers={otherReferenceNumbers}
            remarks={remarksArray}
          />
        </View>
        <XPODeliveryReceiptFooter {...delivery} />
      </Page>
    </PDFWrapper>
  )
}

const styles = StyleSheet.create({
  page: {
    padding: 14,
    fontSize: 7,
    position: 'relative',
    ...Fonts.HelveticaNeue.Normal,
  },
})
