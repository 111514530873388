import { View } from '@react-pdf/renderer'
import { MothershipLogo } from './MothershipLogo'
import { Pane } from './Pane'
import { Text } from './Text'

interface RateConfirmationHeaderProps {
  fixed?: boolean
  dateString: string
  loadNumber: string
}

export const RateConfirmationHeader = (props: RateConfirmationHeaderProps): JSX.Element => {
  const { dateString, loadNumber } = props
  return (
    <Container>
      <MothershipLogo />
      <Pane
        justifyContent="space-between"
        marginTop={30}
        alignItems="flex-end"
        width="100%"
        borderBottom={'2px solid #000000'}
        paddingBottom={8}
      >
        <Pane>
          <Text bold fontSize={12}>
            Load {loadNumber}
          </Text>
        </Pane>
        <Pane direction={'column'} justifyContent="flex-end" alignItems="flex-end">
          <Text fontSize={18} bold>
            Rate Confirmation
          </Text>
          <Text fontSize={7} color={'#545454'} paddingTop={8} bold>
            {dateString}
          </Text>
        </Pane>
      </Pane>
    </Container>
  )
}

const Container = ({ children }: { children: JSX.Element[] }): JSX.Element => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    }}
  >
    {children}
  </View>
)
