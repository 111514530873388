import { Bank } from '../icons/Bank'
import { Fonts } from '../style'
import { Pane } from './Pane'
import { Text } from './Text'

const BORDER = '1px solid #e2e2e2'
export const AccountingBankAccountUpsell = ({ bottomLeftString }: { bottomLeftString?: string }): JSX.Element => (
  <Pane direction={'column'} alignItems={'flex-end'} paddingTop={25} borderBottom={BORDER}>
    <Pane direction={'column'} alignItems={'flex-start'} paddingBottom={17}>
      <Bank />
      <Pane
        color={'#AFAFAF'}
        width={160}
        marginTop={12}
        fontStyle={Fonts.HelveticaNeue.Normal.fontStyle}
        fontFamily={Fonts.HelveticaNeue.Normal.fontFamily}
      >
        <Text>
          <Text bold>Save 2%</Text> on all purchases when you make payments with a bank account.
        </Text>
      </Pane>
    </Pane>
    {bottomLeftString ? (
      <Text bold paddingTop={8} width={'100%'} paddingBottom={9}>
        {bottomLeftString}
      </Text>
    ) : null}
  </Pane>
)
