import { useRouteMatch } from 'react-router-dom'
import { DEFAULT_DOCUMENT_PATH, MatchDocumentPath } from '../router/paths'

export const useDocumentMatch = (): MatchDocumentPath => {
  const match = useRouteMatch<MatchDocumentPath>({
    path: DEFAULT_DOCUMENT_PATH,
  })
  return {
    viewingType: match?.params.viewingType,
    template: match?.params.template,
  }
}
