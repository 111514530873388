import querystring from 'query-string'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthClient } from '../clients/AuthClient'
import { firebaseClient } from '../clients/firebase'
import { config } from '../config'

const REDIRECT_URI = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${config.googleClientId}&response_type=code&redirect_uri=${config.googleLoginRedirectURI}&scope=openid+email+profile&prompt=consent`

interface UseGoogleLoginReturn {
  login: () => void
  logout: () => void
  isAuthed: boolean
  isLoading: boolean
}

let authClient: AuthClient

export const useAuthClient = (): UseGoogleLoginReturn => {
  const [isAuthed, setIsAuthed] = useState<boolean>(false)
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(true)

  const history = useHistory()
  if (!authClient) {
    authClient = new AuthClient()
  }

  const { code } = querystring.parse(window.location.search)
  useEffect(() => {
    if (code) {
      setIsAuthLoading(true)
      authClient.login(code)
    }
  }, [code])

  useEffect(() => {
    const unsubscribe = firebaseClient.auth().onAuthStateChanged((user) => {
      setIsAuthLoading(true)
      validateUserPermissions(user?.uid)
    })
    return unsubscribe
  }, [])

  const validateUserPermissions = async (uid?: string): Promise<void> => {
    const user = await authClient.resolveAuthUserFromUid(uid)

    if (user && user.email.endsWith('@mothership.com')) {
      setIsAuthed(true)
    } else {
      setIsAuthed(false)
      authClient.logout()
    }

    setIsAuthLoading(false)
    history.replace('/')
  }

  const login = (): void => {
    window.location.href = REDIRECT_URI
  }

  const logout = (): void => {
    authClient.logout()
  }

  return {
    login,
    logout,
    isAuthed,
    isLoading: isAuthLoading,
  }
}
