import { Column, Row } from '../Pane'
import { Text } from '../Text'
import {
  BOL_DEFAULT_BORDER,
  BOL_DEFAULT_HEADER_PADDING,
  BOL_DEFAULT_MARGIN,
  BOL_DEFAULT_PADDING,
} from './BillOfLadingDefaults'

interface BillOfLadingNotesProps {
  shipperNotes?: string
  consigneeNotes?: string
  fbaNotes?: string
}

export const BillOfLadingNotes = ({
  shipperNotes,
  consigneeNotes,
  fbaNotes,
}: BillOfLadingNotesProps): JSX.Element | null => {
  if (!shipperNotes && !consigneeNotes && !fbaNotes) {
    return null
  }

  return (
    <Column border={BOL_DEFAULT_BORDER} marginTop={BOL_DEFAULT_MARGIN} wrap={false}>
      <Row
        backgroundColor={'#eeeeee'}
        borderBottom={BOL_DEFAULT_BORDER}
        {...BOL_DEFAULT_HEADER_PADDING}
        alignItems="center"
        justifyContent="space-between"
      >
        <Column>
          <Text bold>Remarks</Text>
        </Column>
      </Row>

      <Column margin={BOL_DEFAULT_PADDING} justifyContent="space-between">
        {shipperNotes ? (
          <Row>
            <Text bold>Shipper Notes: </Text>
            <Text flex={1}>{shipperNotes}</Text>
          </Row>
        ) : (
          <></>
        )}
        {consigneeNotes ? (
          <Row marginTop={shipperNotes ? 2 : ''}>
            <Text bold>Consignee Notes: </Text>
            <Text flex={1}>{consigneeNotes}</Text>
          </Row>
        ) : (
          <></>
        )}
        {fbaNotes ? (
          <Row marginTop={shipperNotes || consigneeNotes ? 2 : ''}>
            <Text bold>FBA#: </Text>
            <Text flex={1}>{fbaNotes}</Text>
          </Row>
        ) : (
          <></>
        )}
      </Column>
    </Column>
  )
}
