import { Pane } from './Pane'
import { Text } from './Text'

export const RateConfirmationCarrierSignature = (): JSX.Element => {
  return (
    <Pane width={'100%'} paddingTop={16}>
      {/** Left Column */}
      <Pane direction="column" flex={1}>
        <Pane width={'100%'} paddingBottom={35}>
          <SignatureBox label="First name (print)" />
          <SignatureBox label="Last name (print)" marginLeft />
        </Pane>
        <SignatureLine />
      </Pane>
      {/** Right Column */}
      <Pane direction="column" paddingLeft={40}>
        <SignatureBox label="Title (print)" useFullWidth />
        <DateEntryBox />
        <Text bold paddingTop={8}>
          Date
        </Text>
      </Pane>
    </Pane>
  )
}

const SignatureBox = ({
  label,
  marginLeft,
  useFullWidth,
}: {
  label: string
  marginLeft?: boolean
  useFullWidth?: boolean
}) => {
  return (
    <Pane
      direction="column"
      marginLeft={marginLeft ? 8 : undefined}
      flex={useFullWidth ? undefined : 1}
      width={useFullWidth ? '100%' : undefined}
    >
      <Pane height={25} width={'100%'} border={'1px solid #000000'} />
      <Text bold paddingTop={8}>
        {label}
      </Text>
    </Pane>
  )
}

const SignatureLine = () => {
  return (
    <Pane direction="column" width={'100%'}>
      <Text bold>x</Text>
      <Pane borderBottom={'1px solid #000000'} width={'100%'} paddingBottom={8} />
      <Text bold paddingTop={8}>
        Signature
      </Text>
    </Pane>
  )
}

const DateEntryBox = () => {
  const DateBox = ({ last }: { last?: boolean }) => (
    <Pane
      width={18}
      height={25}
      borderLeft={'1px solid #000000'}
      borderTop={'1px solid #000000'}
      borderBottom={'1px solid #000000'}
      borderRight={last ? '1px solid #000000' : undefined}
    />
  )
  return (
    <Pane width={'100%'} paddingTop={17}>
      <Pane direction="column">
        <Text fontSize={7} color={'#545454'} paddingBottom={4}>
          Month
        </Text>
        <Pane>
          <DateBox />
          <DateBox last />
        </Pane>
      </Pane>
      <Pane direction="column" paddingLeft={8}>
        <Text fontSize={7} color={'#545454'} paddingBottom={4}>
          Date
        </Text>
        <Pane>
          <DateBox />
          <DateBox last />
        </Pane>
      </Pane>
      <Pane direction="column" paddingLeft={8}>
        <Text fontSize={7} color={'#545454'} paddingBottom={4}>
          Year
        </Text>
        <Pane>
          <DateBox />
          <DateBox />
          <DateBox />
          <DateBox last />
        </Pane>
      </Pane>
    </Pane>
  )
}
