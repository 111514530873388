import { View } from '@react-pdf/renderer'
import { MothershipLogo } from './MothershipLogo'
import { Pane } from './Pane'
import { Text } from './Text'
export interface IAccountingHeaderBadge {
  label: string
  filled: boolean
}

export interface IAccountingHeaderBillTo {
  name?: string
  streetAddress1?: string
  streetAddress2?: string
  city?: string
  state?: string
  zip?: string
}
interface AccountingHeaderProps {
  fixed?: boolean
  title: string
  description?: string
  badge?: IAccountingHeaderBadge
  billTo?: IAccountingHeaderBillTo
}

export const AccountingHeader = (props: AccountingHeaderProps): JSX.Element => {
  const { fixed, title, description, badge, billTo } = props
  return (
    <Pane fixed={fixed} width={'100%'} direction="column">
      <Pane justifyContent="space-between" width="100%">
        <MothershipLogo />
        {badge ? (
          <Badge filled={badge.filled}>
            <Text color={'white'} fontSize={10} bold>
              {badge.label}
            </Text>
          </Badge>
        ) : null}
      </Pane>
      <Pane
        justifyContent="space-between"
        marginTop={30}
        alignItems="flex-end"
        width="100%"
        borderBottom={'2px solid #000000'}
        paddingBottom={8}
      >
        {billTo ? (
          <Pane direction="column" justifyContent="flex-end">
            <Text bold>Bill to</Text>
            <Text>{billTo.name || ' '}</Text>
            {billTo.streetAddress1 ? (
              <Text>
                {billTo.streetAddress1 || ' '}
                {billTo.streetAddress2 ? `, ${billTo.streetAddress2}` : ' '}
              </Text>
            ) : null}
            {billTo.city ? (
              <Text>
                {billTo.city ? `${billTo.city}, ` : ''} {billTo.state || ''} {billTo.zip || ''}
              </Text>
            ) : null}
          </Pane>
        ) : (
          <Pane flex={1} />
        )}
        <Pane direction="column" alignItems={'flex-end'}>
          <Text fontSize={18} bold>
            {title}
          </Text>
          {description ? (
            <Text bold fontSize={8} color={'#545454'} paddingTop={8}>
              {description}
            </Text>
          ) : null}
        </Pane>
      </Pane>
    </Pane>
  )
}

const Badge = ({
  children,
  filled,
}: {
  children: Array<JSX.Element | undefined | null> | JSX.Element
  filled: boolean
}): JSX.Element => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4 8',
      backgroundColor: filled ? '#000000' : '#757575',
      borderRadius: 2,
    }}
  >
    {children}
  </View>
)
