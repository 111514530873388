import { View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import _ from 'lodash'

interface PaneProps extends Style {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  fixed?: boolean
  debug?: boolean
  wrap?: boolean
}

export const Pane = (props: PaneProps): JSX.Element => {
  const styleProps: Style = _.omit(props, 'children', 'direction', 'fixed', 'debug', 'wrap')

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: props.direction ?? props.flexDirection ?? 'row',
        ...styleProps,
      }}
      fixed={props.fixed ?? false}
      debug={props.debug ?? false}
      wrap={props.wrap ?? true}
    >
      {props.children}
    </View>
  )
}

export const Row = (props: PaneProps): JSX.Element => Pane({ ...props, direction: 'row' })
export const Column = (props: PaneProps): JSX.Element => Pane({ ...props, direction: 'column' })
