import { capitalize } from 'lodash'
import { generatePath, useHistory } from 'react-router-dom'
import { Pane, useTheme, SegmentedControl, Heading } from 'evergreen-ui'
import { DEFAULT_DOCUMENT_PATH, DocumentViewingType } from '../router/paths'
import { useDocumentMatch } from '../hooks/useDocumentMatch'

export const DocumentTopBar = (): JSX.Element | null => {
  const theme = useTheme()
  const history = useHistory()
  const { viewingType, template } = useDocumentMatch()
  return (
    <Pane
      height={'100%'}
      width={'100%'}
      borderBottom={`1px solid ${theme.colors.border.default}`}
      display="flex"
      alignItems={'center'}
      justifyContent={'space-between'}
      paddingLeft={16}
      paddingRight={16}
    >
      <Heading size={500} width={100}>
        {capitalize(template)}
      </Heading>
      <SegmentedControl
        name="switch"
        width={240}
        options={[
          {
            label: 'PDF',
            value: DocumentViewingType.PDF,
          },
          {
            label: 'Web',
            value: DocumentViewingType.Web,
          },
        ]}
        value={viewingType}
        onChange={(newViewingType) => {
          history.push(
            generatePath(DEFAULT_DOCUMENT_PATH, {
              viewingType: newViewingType,
              template,
            }),
          )
        }}
      />
      <Pane width={100} />
    </Pane>
  )
}
