import { Column, Pane, Row } from '../Pane'
import { Text } from '../Text'
import {
  BOL_DEFAULT_BORDER,
  BOL_DEFAULT_HEADER_PADDING,
  BOL_DEFAULT_MARGIN,
  BOL_DEFAULT_PADDING,
} from './BillOfLadingDefaults'

const Checkbox = ({ isChecked }: { isChecked?: boolean }) => (
  <Pane width={12} height={12} backgroundColor={'#ffffff'} border={BOL_DEFAULT_BORDER} marginLeft={BOL_DEFAULT_PADDING}>
    <Text marginLeft={2.5}>{isChecked ? 'X' : ''}</Text>
  </Pane>
)

interface BillOfLadingInspectionProps {
  type: 'Pick-up' | 'Drop-off'
  hasInspectionPhotos?: boolean
  inspectionNotes?: string
}

export const BillOfLadingInspection = (props: BillOfLadingInspectionProps): JSX.Element => {
  return (
    <Column minHeight={60} border={BOL_DEFAULT_BORDER} marginTop={BOL_DEFAULT_MARGIN} wrap={false}>
      <Row
        backgroundColor={'#eeeeee'}
        width={'100%'}
        borderBottom={BOL_DEFAULT_BORDER}
        {...BOL_DEFAULT_HEADER_PADDING}
        alignItems="center"
        justifyContent="space-between"
      >
        <Column>
          <Text bold>{props.type} Inspection & Signature</Text>
          <Text>Please leave detailed notes below</Text>
        </Column>
        <Row justifyContent="center" alignItems="center">
          <Text bold>Freight damaged</Text>
          <Checkbox />
        </Row>
        <Row justifyContent="center" alignItems="center">
          <Text bold>Freight missing</Text>
          <Checkbox />
        </Row>
        <Row justifyContent="center" alignItems="center">
          <Text bold>Photos taken</Text>
          <Checkbox isChecked={props.hasInspectionPhotos} />
        </Row>
      </Row>
      <Row>
        <Text padding={3} textAlign="left" flex={1}>
          {props.inspectionNotes}
        </Text>
      </Row>
    </Column>
  )
}
