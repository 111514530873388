import { BillOfLadingThirdPartyBillingAddress } from '@mothership/document-types'
import moment from 'moment-timezone'
import {
  MOTHERSHIP_BILLING_ADDRESS_CITY_STATE_ZIP,
  MOTHERSHIP_BILLING_ADDRESS_NAME,
  MOTHERSHIP_BILLING_ADDRESS_STREET,
  MOTHERSHIP_PO_BOX_ADDRESS_CITY_STATE_ZIP,
  MOTHERSHIP_PO_BOX_ADDRESS_STREET,
} from '../../utils/billing'
import { Column } from '../Pane'
import { Text } from '../Text'
import {
  BOL_DEFAULT_BORDER,
  BOL_DEFAULT_HEADER_PADDING,
  BOL_DEFAULT_MARGIN,
  BOL_DEFAULT_PADDING,
} from './BillOfLadingDefaults'

interface BillOfLadingBillingProps {
  freightReadyTimeAt: string
  thirdPartyBillingAddress: BillOfLadingThirdPartyBillingAddress | undefined
}

export const BillOfLadingBilling = (props: BillOfLadingBillingProps): JSX.Element => {
  const { freightReadyTimeAt, thirdPartyBillingAddress } = props
  const addressName = thirdPartyBillingAddress?.name ?? MOTHERSHIP_BILLING_ADDRESS_NAME

  // NOTE [jdao]: This is a hack to use our "old" address prior to pickups on 2024/11/01 12:00AM PST
  let street: string
  let cityStateZip: string
  if (moment(freightReadyTimeAt).isBefore(moment('2024-11-01T07:00:00.000Z'))) {
    street = MOTHERSHIP_PO_BOX_ADDRESS_STREET
    cityStateZip = MOTHERSHIP_PO_BOX_ADDRESS_CITY_STATE_ZIP
  } else {
    street = thirdPartyBillingAddress?.street ?? MOTHERSHIP_BILLING_ADDRESS_STREET
    cityStateZip =
      thirdPartyBillingAddress?.city && thirdPartyBillingAddress?.state && thirdPartyBillingAddress?.zip
        ? `${thirdPartyBillingAddress.city}, ${thirdPartyBillingAddress.state} ${thirdPartyBillingAddress.zip}`
        : MOTHERSHIP_BILLING_ADDRESS_CITY_STATE_ZIP
  }

  return (
    <Column width={'100%'} border={BOL_DEFAULT_BORDER} marginTop={BOL_DEFAULT_MARGIN}>
      <Text
        backgroundColor="#eeeeee"
        borderBottom={BOL_DEFAULT_BORDER}
        width={'100%'}
        bold
        {...BOL_DEFAULT_HEADER_PADDING}
      >
        All freight charges are PRE-PAID - 3rd party bill to
      </Text>
      <Column padding={BOL_DEFAULT_PADDING} width={'100%'}>
        <Text>{addressName}</Text>
        <Text>{street}</Text>
        <Text>{cityStateZip}</Text>
      </Column>
    </Column>
  )
}
