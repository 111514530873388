import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { config } from '../config'

export const getOnce = async <TValue>(fbKey: string): Promise<TValue> => {
  const snapshot = await firebase.database().ref(fbKey).once('value')
  return snapshot.val() as TValue
}

export const firebaseClient = firebase.initializeApp({
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  databaseURL: config.firebase.databaseUrl,
  storageBucket: config.firebase.storageBucket,
  projectId: config.firebase.projectID,
})
