import { Style } from '@react-pdf/types'

export type FontStyle = Pick<Style, 'fontFamily' | 'fontStyle'>

export const Fonts = {
  Handwritten: {
    Normal: {
      fontFamily: 'Handwritten',
      fontStyle: 'normal',
    } as FontStyle,
  },
  HelveticaNeue: {
    Normal: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
    } as FontStyle,
    Bold: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'bold',
    } as FontStyle,
  },
  Courier: {
    Normal: {
      fontFamily: 'Courier',
      fontStyle: 'normal',
    } as FontStyle,
  },
  Signature: {
    Normal: {
      fontFamily: 'Signature',
      fontStyle: 'normal',
    } as FontStyle,
  },
}
