import axios from 'axios'
import { config } from '../config'
import { User } from '../models/User'
import { firebaseClient } from './firebase'

interface GoogleAuthLoginData {
  authCode: string | string[]
  source: 'google'
  redirectURI: string
}

export class AuthClient {
  public async resolveAuthUserFromUid(uid?: string): Promise<User | null> {
    if (!uid) {
      return null
    }
    try {
      const authToken = await this._getFirebaseAuthToken()
      if (!authToken) {
        return null
      }

      const response = await axios.get<User>(`${config.axel.baseUrl}/user/${uid}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })

      return response.data
    } catch (error) {
      console.error(error)
    }
    return null
  }

  public async login(code: string | string[]): Promise<User | null> {
    try {
      const data: GoogleAuthLoginData = {
        authCode: code,
        source: 'google',
        redirectURI: config.googleLoginRedirectURI,
      }
      const response = await axios.post<{ authToken: string }>(config.authLoginUrl, data)

      const fbUser = await firebaseClient.auth().signInWithCustomToken(response.data.authToken)
      if (!fbUser.user?.uid) {
        return null
      }

      const user = await this.resolveAuthUserFromUid(fbUser.user.uid)
      if (!user) {
        return null
      }

      return user
    } catch (error) {
      console.error(error)
    }
    return null
  }

  public async logout(): Promise<void> {
    try {
      firebaseClient.auth().signOut()
    } catch (error) {
      console.error(error)
    }
  }

  private async _getFirebaseAuthToken(): Promise<string | null> {
    const auth = firebaseClient.auth()

    if (auth.currentUser) {
      const authToken = await auth.currentUser.getIdToken()
      return authToken
    }

    return null
  }
}
