import { Pane } from './Pane'
import { Text } from './Text'

export const RateConfirmationCarrierAgreement = (): JSX.Element => {
  return (
    <Pane direction="column" paddingTop={8}>
      <Text bold>To accept this Load and Rate Confirmation, the Carrier must:</Text>
      <Text bold paddingTop={8}>
        Sign up through the Mothership App, providing all acceptable required information and agreeing to the Terms of
        Service.
      </Text>
      <Text bold paddingTop={8}>
        By accepting this load, Carrier confirms and agrees:
      </Text>
      <Text paddingTop={8}>
        1. Carrier maintains accident liability insurance of at least $1,000,000 and cargo insurance coverage of at
        least $100,000.
      </Text>
      <Text paddingTop={2}>2. No additional charges or fees will be added by Carrier.</Text>
      <Text paddingTop={2}>
        3. The load will not be double-brokered, or co-brokered, and that Carrier will transport the load.
      </Text>
      <Text paddingTop={2}>
        4. All vehicles used to transport the load are maintained in accordance with all applicable statutes,
        regulations and rules.
      </Text>
      <Text paddingTop={2}>5. All loads are subject to electronic tracking.</Text>
      <Text paddingTop={2}>
        6. To the Terms of Service contained within the Mothership App apply to the load and transport.
      </Text>
      <Text bold paddingTop={8}>
        Carrier may view the Terms of Service by visiting:
        https://support.mothership.com/terms-and-policies/terms-of-service
      </Text>
    </Pane>
  )
}
