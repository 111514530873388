import { Text as RenderText } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import _ from 'lodash'
import { FontStyle } from '../style'

export interface TextProps extends Style {
  center?: boolean
  bold?: boolean
  font?: FontStyle
  children?:
    | JSX.Element
    | JSX.Element[]
    | undefined
    | null
    | string
    | string[]
    | number
    | Array<string | number>
    | (string | JSX.Element)[]
}

export const Text = (props: TextProps): JSX.Element => {
  const fontFamily = props.font?.fontFamily ?? props.fontFamily
  const fontStyle = props.bold ? 'bold' : props.font?.fontStyle ?? props.fontStyle
  const styleProps: Style = _.omit(props, 'center', 'bold', 'font', 'children')

  return (
    <RenderText
      style={{
        ...styleProps,
        textAlign: props.center ? 'center' : props.textAlign,
        fontFamily,
        fontStyle,
      }}
    >
      {props.children}
    </RenderText>
  )
}
