import { Pane, Heading } from 'evergreen-ui'

export const HomePage = (): JSX.Element => {
  return (
    <Pane flexDirection="column" justifyContent="center" width={'100%'} padding={24}>
      <Heading size={900}>Welcome to the Mothership Document Explorer</Heading>
      <Heading size={200} marginTop="default">
        Please select a document from the side bar to view it
      </Heading>
    </Pane>
  )
}
