import { CarrierEarningReportParams, PayoutParams } from '../CarrierEarningReport'

const genShipmentPayout = (): PayoutParams => ({
  completedAt: '2024-05-14T19:57:36.293Z',
  referenceNumber: 'MS123456',
  description: '3 pallets (6000 lbs)',
  payoutAmountCents: Math.random() * 1000,
  pickupLocation: {
    name: 'Mothership Technologies, Inc.',
    streetAddress1: '1234 Street Address Way',
    streetAddress2: 'Suite A',
    city: 'Unicorn Valley',
    state: 'CA',
    zip: '90016',
  },
  deliveryLocation: {
    name: 'Mothership Technologies, Inc.',
    streetAddress1: '1234 Street Address Way',
    city: 'Unicorn Valley',
    state: 'CA',
    zip: '90016',
  },
})

const genReferralPayout = (description: string, payoutAmountCents: number): PayoutParams => ({
  referenceNumber: null,
  completedAt: null,
  pickupLocation: null,
  deliveryLocation: null,
  description,
  payoutAmountCents,
})

const payouts = [
  genShipmentPayout(),
  genShipmentPayout(),
  genShipmentPayout(),
  genReferralPayout('1st shipment • MCR3502626', 5000),
  genReferralPayout('Qualified customer • MCR3502626', 2500),
  genReferralPayout('Qualified customer • MCR3829959', 2500),
  genReferralPayout('Rev share 1% • 3x • MCR3502626', 150),
]

export const CarrierEarnings: CarrierEarningReportParams = {
  carriers: {
    ['Jack Mothership']: {
      totalEarnedCents: payouts.reduce((prev, p) => prev + p.payoutAmountCents, 0),
      name: 'Jack Mothership',
      payouts,
    },
  },
  companyName: 'Mothership',
  periodStartAt: '2024-05-07T07:00:00.000Z',
  periodEndAt: '2024-05-14T07:00:00.000Z',
  totalEarnedCents: payouts.reduce((prev, p) => prev + p.payoutAmountCents, 0),
}
