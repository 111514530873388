import { Button, Heading, Pane, Spinner } from 'evergreen-ui'
import styled from 'styled-components'
import { useAuthClient } from '../hooks/useAuthClient'

export const Login = ({ loading }: { loading: boolean }): JSX.Element => {
  const { login } = useAuthClient()
  return (
    <Container>
      <Pane
        elevation={2}
        backgroundColor={'#ffffff'}
        padding={16}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Heading size={100} marginTop="default">
          Mothership Document Explorer
        </Heading>
        {loading ? (
          <Spinner marginTop={16} />
        ) : (
          <Button appearance="primary" intent="none" marginTop={16} onClick={login}>
            Login with Google
          </Button>
        )}
      </Pane>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
`
