import { XPODeliveryReceiptDocumentGenerationData } from '@mothership/document-types'
import { Pane } from './Pane'
import { Text } from './Text'

interface StakeholderInfo {
  name1: string
  name2?: string
  address: string
  city: string
  state: string
  country: string
  postalCode: string
}

type Notification = XPODeliveryReceiptDocumentGenerationData['notification']
interface XPODeliveryReceiptStakeholdersProps {
  shipper: StakeholderInfo
  consignee: StakeholderInfo
  billTo?: StakeholderInfo
  notification?: Notification
}

const BORDER = '1px solid black'

export const XPODeliveryReceiptStakeholders = (props: XPODeliveryReceiptStakeholdersProps): JSX.Element => {
  return (
    <Pane borderBottom={BORDER} borderLeft={BORDER} borderRight={BORDER} width={'100%'} backgroundColor={'white'}>
      <Box label="CONSIGNEE" value={createStakeholderStrings(props.consignee)} />
      <Box label="SHIPPER" value={createStakeholderStrings(props.shipper)} />
      <Box label="BILL TO" value={createStakeholderStrings(props.billTo)} />
      <Box label={props.notification?.message ?? ''} last value={createNotificationString(props.notification)} />
    </Pane>
  )
}

const createNotificationString = (notification?: Notification): string[] => {
  if (!notification) {
    return []
  }
  return [
    notification?.notificationTime ?? '',
    notification?.notificationDate ?? '',
    notification?.messageObservation ?? '',
  ].filter((s) => Boolean(s))
}

const createStakeholderStrings = (stakeholder?: StakeholderInfo): string[] => {
  if (!stakeholder) return []
  const locality = stakeholder.city + ', ' + [stakeholder.state, stakeholder.country, stakeholder.postalCode].join(' ')
  return [stakeholder.name1, stakeholder.name2, stakeholder.address, locality].filter((s) => Boolean(s)) as string[]
}

const Box = ({ label, last, value }: { label: string; last?: boolean; value?: string | string[] }): JSX.Element => {
  let displayedValue = value ?? ''
  if (!Array.isArray(displayedValue)) {
    displayedValue = [displayedValue]
  }

  return (
    <Pane
      direction="column"
      borderRight={!last ? '1px solid black' : undefined}
      flex={last ? 0.5 : 1}
      paddingTop={2}
      paddingBottom={2}
      paddingLeft={2}
    >
      <Text bold>{label.toUpperCase()}</Text>
      {displayedValue.map((v: string) => (
        <Text key={v} paddingTop={2}>
          {v.toUpperCase()}
        </Text>
      ))}
    </Pane>
  )
}
