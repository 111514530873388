import { DocumentTemplate } from '@mothership/document-types'

export enum DocumentViewingType {
  PDF = 'pdf',
  Web = 'web',
}

export interface MatchDocumentPath {
  viewingType?: DocumentViewingType
  template?: DocumentTemplate
}

export const DEFAULT_DOCUMENT_PATH = '/document/:viewingType?/:template?'
