import { WaypointType } from '@mothership/shipment-types/models'
import { timeUtils } from '@mothership/utils-ts'
import { View } from '@react-pdf/renderer'
import moment from 'moment-timezone'
import { freightTypeAndQuantityString } from '../utils/freight'
import { formatPhoneNumberToNational } from '../utils/string'
import { Pane } from './Pane'
import { Text } from './Text'
import { RateConfirmationShipmentFreightDto, RateConfirmationShipmentWaypointDto } from '@mothership/document-types'

interface RateConfirmationStopProps {
  waypoint: RateConfirmationShipmentWaypointDto
  stopNumber: number
  freight: Array<RateConfirmationShipmentFreightDto>
  stopType: WaypointType
}

const BORDER = '1px solid #CBCBCB'
const GREY_TEXT = '#545454'

const waypointTypeToString = (type: WaypointType): 'Pick-up' | 'Delivery' | 'Other' => {
  switch (type) {
    case WaypointType.Pickup:
      return 'Pick-up'
    case WaypointType.Delivery:
      return 'Delivery'
    default:
      return 'Other'
  }
}

const accessorialString = (waypoint: RateConfirmationShipmentWaypointDto): string | undefined => {
  const accessorials = []
  if (waypoint.accessorialAppointment) {
    accessorials.push('Appointment')
  }

  if (waypoint.accessorialCfs) {
    accessorials.push('CFS')
  }

  if (waypoint.accessorialInside) {
    accessorials.push('LTL Inside Service')
  }

  if (waypoint.accessorialLocalInsideDelivery) {
    accessorials.push('Local Inside Delivery')
  }

  if (waypoint.accessorialLiftgate) {
    accessorials.push('Liftgate')
  }

  if (waypoint.accessorialResidential) {
    accessorials.push('Residential')
  }

  if (waypoint.accessorialTradeShow) {
    accessorials.push('Trade Show')
  }

  return accessorials.length > 0 ? accessorials.join(', ') : undefined
}

export const RateConfirmationStop = (props: RateConfirmationStopProps): JSX.Element => {
  const { waypoint, stopNumber, freight, stopType } = props
  const waypointServiceDateMoment = moment.tz(waypoint.serviceDate, timeUtils.ISO_DATE_FORMAT, waypoint.timeZone)
  const freightReadyTimeMoment = waypoint.readyTime
    ? timeUtils.parseMomentFromMilitaryTime({
        militaryTime: waypoint.readyTime,
        timezoneId: waypoint.timeZone,
        onDayOfUnixTimestamp: waypoint.serviceWindowStartAt,
      })
    : null
  const waypointServiceTimeMoment = moment(
    stopType === WaypointType.Pickup ? freightReadyTimeMoment?.valueOf() : waypoint.serviceWindowEndAt,
  ).tz(waypoint.timeZone)
  const waypointAccessorialsString = accessorialString(waypoint)
  let totalWeightAtLocation = 0
  freight.forEach((item) => {
    totalWeightAtLocation += item.weight * item.quantity
  })
  return (
    <Pane
      direction="column"
      marginTop={stopNumber > 1 ? 24 : undefined}
      width={'100%'}
      borderTop={stopNumber > 1 ? '1px solid #000000' : undefined}
    >
      <Pane
        direction="row"
        justifyContent="space-between"
        borderBottom={BORDER}
        paddingTop={12}
        paddingBottom={12}
        width={'100%'}
      >
        <Text bold fontSize={12}>
          Stop {stopNumber}: {waypointTypeToString(waypoint.type)}
        </Text>
        <Text bold fontSize={12}>
          {stopType === WaypointType.Pickup ? 'Freight ready' : 'Deliver by'}:{' '}
          {waypointServiceDateMoment.format('ddd, MMM D, YYYY')} {stopType === WaypointType.Pickup ? 'after' : 'before'}{' '}
          {waypointServiceTimeMoment.format('h:mma z')}
        </Text>
      </Pane>
      <Pane width={'100%'} justifyContent="space-between">
        {/** LOCATION COLUMN */}
        <Pane direction="column" paddingTop={8} width={140} marginRight={40}>
          <Text bold marginBottom={4} fontSize={8}>
            {waypoint.locationName ?? 'Pickup Location'}
          </Text>
          <DescriptiveText>{waypoint.streetAddress1}</DescriptiveText>
          {waypoint.streetAddress2 ? <DescriptiveText>{waypoint.streetAddress2}</DescriptiveText> : null}
          <DescriptiveText>
            {waypoint.city}, {waypoint.state} {waypoint.zipCode}
          </DescriptiveText>
          <DescriptiveText>{formatPhoneNumberToNational(waypoint.contactPhoneNumber)}</DescriptiveText>
          <DescriptiveText>
            Hours: {moment(waypoint.openTime, 'h:mm:ss').format('h:mma')} -{' '}
            {moment(waypoint.closeTime, 'h:mm:ss').format('h:mma')} {moment().tz(waypoint.timeZone).format('z')}
          </DescriptiveText>

          {waypoint.shipperReferenceNumber ? (
            <>
              <Text bold marginBottom={4} paddingTop={8} fontSize={8}>
                Reference #
              </Text>
              <DescriptiveText>{waypoint.shipperReferenceNumber}</DescriptiveText>
            </>
          ) : null}

          {waypointAccessorialsString ? (
            <>
              <Text bold marginBottom={4} paddingTop={8} fontSize={8}>
                Accessorials / driver work
              </Text>
              <DescriptiveText>{waypointAccessorialsString}</DescriptiveText>
            </>
          ) : null}

          {waypoint.shipperNotes ? (
            <>
              <Text bold marginBottom={4} paddingTop={8} fontSize={8}>
                Notes
              </Text>
              <DescriptiveText>{waypoint.shipperNotes}</DescriptiveText>
            </>
          ) : null}
        </Pane>
        {/** FREIGHT COLUMN */}
        <Pane direction="column" paddingTop={8} flex={1} width={'100%'}>
          <Pane paddingBottom={8} width={'100%'}>
            <Text bold width={70} fontSize={8}>
              Handling unit(s)
            </Text>
            <Text bold width={70} fontSize={8}>
              Piece count
            </Text>
            <Text bold flex={1} fontSize={8}>
              Description
            </Text>
            <Text bold width={70} textAlign="right" fontSize={8}>
              Weight
            </Text>
          </Pane>
          {freight.map((item) => (
            <Pane borderTop={BORDER} paddingTop={8} paddingBottom={8} width={'100%'} key={item.id}>
              <Text width={70}>{freightTypeAndQuantityString(item.type, item.quantity)}</Text>
              <Text width={70}>-</Text>
              <Pane direction="column" flex={1}>
                <DescriptiveText width={'100%'}>{item.description}</DescriptiveText>
                <Pane>
                  {item.alcohol ? <Accessorial>Alcohol</Accessorial> : null}
                  {item.tobacco ? <Accessorial>Tobacco</Accessorial> : null}
                  {item.hazmat ? <Accessorial>Hazmat</Accessorial> : null}
                </Pane>
              </Pane>

              <Text width={70} textAlign="right">
                {(item.weight * item.quantity).toLocaleString('en-US')} lbs
              </Text>
            </Pane>
          ))}
          <Pane borderTop={BORDER} paddingTop={8} paddingBottom={8} width={'100%'} justifyContent="space-between">
            <Text bold fontSize={8}>
              Total weight loaded
            </Text>
            <Text bold fontSize={8}>
              {totalWeightAtLocation.toLocaleString('en-US')} lbs
            </Text>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}

const DescriptiveText = ({
  children,
  flex,
  width,
}: {
  children: string | string[] | null
  flex?: number
  width?: string
}): JSX.Element => (
  <Text color={GREY_TEXT} paddingTop={1} flex={flex} width={width}>
    {children}
  </Text>
)

const AccBubble = ({ children }: { children: JSX.Element[] }): JSX.Element => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#000000',
      padding: 5,
      borderRadius: 2,
      marginRight: 4,
      marginTop: 10,
    }}
  >
    {children}
  </View>
)

const Accessorial = ({ children }: { children: string }): JSX.Element => {
  return (
    <AccBubble>
      <View
        style={{
          backgroundColor: '#ffffff',
          width: 4,
          height: 4,
          transform: 'rotate(45deg)',
        }}
      />
      <Text color="#ffffff" bold paddingLeft={4}>
        {children}
      </Text>
    </AccBubble>
  )
}
