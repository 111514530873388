import { Row } from '../Pane'
import { Text } from '../Text'
import { BOL_DEFAULT_MARGIN } from './BillOfLadingDefaults'

export const BillOfLadingNotice = (): JSX.Element => {
  return (
    <Row marginTop={BOL_DEFAULT_MARGIN}>
      <Text fontSize={7}>
        NOTICE: Freight moving under this Bill of Lading is subject to classifications and tariffs established by the
        carrier and are available to shipper upon request. This notice supersedes and negates any claimed oral or
        written contract, promise, representation, or understanding between parties, except to the extent of any written
        contract signed by both parties to the contract. Any unauthorized alteration or use of this bill of lading or
        the tendering of this shipment to any carrier other than that designated by company, may VOID company's
        obligations to make any payments relating to this shipment and VOID all rate quotes. All shippers, consignors,
        consignees, freight forwarders or freight brokers are jointly and severally liable for the freight charges
        relating to this shipment. CUSTOMER AGREES TO THE ORGANIZATION'S TERMS AND CONDITIONS, WHICH CAN BE FOUND AT
        WWW.MOTHERSHIP.COM. The Shipper certifies that the above named materials are properly classified, described,
        marked, labeled and packaged, and are in proper condition for transportation, according to the applicable
        regulations of the Department of Transportation.
      </Text>
    </Row>
  )
}
