import { Pane } from './Pane'
import { Text } from './Text'

interface XPODeliveryReceiptShipperNumbersProps {
  shipperNumbers?: string
}

const BORDER = '1px solid black'

export const XPODeliveryReceiptShipperNumbers = (props: XPODeliveryReceiptShipperNumbersProps): JSX.Element => {
  const { shipperNumbers = '' } = props
  return (
    <Pane
      borderBottom={BORDER}
      borderLeft={BORDER}
      borderRight={BORDER}
      width={'100%'}
      alignItems="center"
      justifyContent="flex-start"
      backgroundColor="white"
      padding={2}
    >
      <Text bold paddingRight={2}>
        SHIPPER NUMBERS
      </Text>
      <Text paddingLeft={6}>{shipperNumbers.replace(/\n/g, ' ')}</Text>
    </Pane>
  )
}
