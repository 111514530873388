import { InvoiceDocumentGenerationData } from '@mothership/document-types'
import { InvoiceDtoStatus } from '@mothership/finance/dist/api'
import { ShipmentInvoiceMetadata } from '@mothership/shipment-types/accounting'
import { Page, StyleSheet } from '@react-pdf/renderer'
import moment from 'moment'
import { AccountingBankAccountUpsell } from '../components/AccountingBankAccountUpsell'
import { AccountingHeader, IAccountingHeaderBadge } from '../components/AccountingHeader'
import { AccountingInvoiceBreakdown } from '../components/AccountingInvoiceBreakdown'
import { DefaultFooter } from '../components/DefaultFooter'
import { PDFWrapper } from '../components/PDFWrapper'
import { DocumentType } from './DocumentType'

const styles = StyleSheet.create({
  page: {
    padding: 32,
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontSize: 7,
  },
})

export const Invoice: DocumentType<InvoiceDocumentGenerationData> = ({
  renderValues: invoice,
}: {
  renderValues: InvoiceDocumentGenerationData
}): JSX.Element => {
  const shipmentInvoiceMetadata = invoice.metadata as Partial<ShipmentInvoiceMetadata> | null

  const issueDate = moment(invoice.createdAt).utc().format('ddd MMM D, YYYY')
  const displayBankAccountUpsell = invoice.payments.find((payment) => payment.sourceType === 'card')
  const renderBadge = (): IAccountingHeaderBadge => {
    switch (invoice.status) {
      case InvoiceDtoStatus.Paid:
        return {
          label: 'Paid',
          filled: true,
        }
      case InvoiceDtoStatus.Voided:
        return {
          label: 'Void - Do not pay',
          filled: true,
        }
      // TODO - Create a past due badge
      default:
        return {
          label: 'Unpaid',
          filled: false,
        }
    }
  }
  return (
    <PDFWrapper>
      <Page size="A4" style={styles.page}>
        <AccountingHeader
          fixed
          title={`Invoice ${invoice.referenceNumber}`}
          description={`Issued on ${issueDate}`}
          badge={renderBadge()}
          billTo={{
            name: invoice.contact.organizationName,
          }}
        />
        {displayBankAccountUpsell ? <AccountingBankAccountUpsell /> : null}
        <AccountingInvoiceBreakdown invoice={invoice} shipmentInvoiceMetadata={shipmentInvoiceMetadata} />
        <DefaultFooter fixed />
      </Page>
    </PDFWrapper>
  )
}
