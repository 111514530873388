import { Pane } from './Pane'
import { Text } from './Text'

interface XPODeliveryReceiptServiceProps {
  equipmentNumber?: string
  date?: string
  origin?: string
  destination?: string
  revenue?: string
  advance?: string
  beyond?: string
  route?: string[]
}

const BORDER = '1px solid black'

export const XPODeliveryReceiptService = (props: XPODeliveryReceiptServiceProps): JSX.Element => {
  return (
    <Pane borderBottom={BORDER} borderLeft={BORDER} borderRight={BORDER} width={'100%'} backgroundColor={'white'}>
      <Box label="EQUIP NUMBER" value={props.equipmentNumber} first />
      <Box label="DATE" value={props.date} width={40} />
      <Box label="ORIGIN" value={props.origin} width={60} />
      <Box label="DESTINATION" value={props.destination} width={60} />
      <Box label="OUR REVENUE" value={props.revenue} width={60} />
      <Box label="ADVANCE" value={props.advance} width={140} />
      <Box label="BEYOND" value={props.beyond} width={65} />
      <Box label="ROUTE" value={props.route} width={60} />
    </Pane>
  )
}

const Box = ({
  label,
  value,
  first,
  width,
}: {
  label: string
  value?: string | string[]
  first?: boolean
  width?: number
}): JSX.Element => {
  let displayedValue = value ?? ''
  if (!Array.isArray(displayedValue)) {
    displayedValue = [displayedValue]
  }

  return (
    <Pane
      direction="column"
      borderLeft={!first ? '1px solid black' : undefined}
      flex={first ? 1 : undefined}
      width={width}
      paddingTop={2}
      paddingBottom={4}
    >
      <Text bold center>
        {label.toUpperCase()}
      </Text>
      {displayedValue.map((v) => (
        <Text key={v} textAlign="center" paddingTop={2}>
          {v.toUpperCase()}
        </Text>
      ))}
    </Pane>
  )
}
