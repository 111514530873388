import { DocumentTemplate } from '@mothership/document-types'
import { Badge, Button, DocumentIcon, LogOutIcon, Menu, Pane, toaster, useTheme } from 'evergreen-ui'
import { generatePath, useHistory } from 'react-router-dom'
import { useAuthClient } from '../hooks/useAuthClient'
import { useDocumentMatch } from '../hooks/useDocumentMatch'
import { DEFAULT_DOCUMENT_PATH, DocumentViewingType } from '../router/paths'

const ComingSoonBadge = () => (
  <Badge color="purple" isSolid marginRight={8}>
    COMING SOON
  </Badge>
)

export const Navigator = (): JSX.Element => {
  const { logout } = useAuthClient()
  const theme = useTheme()
  const history = useHistory()
  const { viewingType = DocumentViewingType.PDF, template } = useDocumentMatch()

  const pushDocument = (documentTemplate: DocumentTemplate): void => {
    const path = generatePath(DEFAULT_DOCUMENT_PATH, {
      viewingType,
      template: documentTemplate,
    })
    history.push(path)
    toaster.success(`Now viewing document: ${documentTemplate}`)
  }

  const isDocumentSelected = (documentTemplate: DocumentTemplate): boolean => {
    return documentTemplate === template
  }

  const MenuItem = ({
    label,
    icon,
    documentTemplate,
    badge,
  }: {
    label: string
    icon?: JSX.Element
    documentTemplate: DocumentTemplate
    badge?: JSX.Element
  }): JSX.Element => {
    return (
      <Menu.Item
        icon={icon ?? DocumentIcon}
        secondaryText={badge}
        onSelect={() => pushDocument(documentTemplate)}
        backgroundColor={isDocumentSelected(documentTemplate) ? theme.colors.background.greenTint : undefined}
      >
        {label}
      </Menu.Item>
    )
  }

  return (
    <Pane
      height={'100%'}
      width={'100%'}
      borderRight={`1px solid ${theme.colors.border.default}`}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Menu>
        <Menu.Group title="Shipment">
          <MenuItem label="Bill of Lading" documentTemplate={DocumentTemplate.BillOfLading} />
          <MenuItem label="Shipping Label" documentTemplate={DocumentTemplate.ShippingLabel} />
          <MenuItem label="Rate Confirmation" documentTemplate={DocumentTemplate.RateConfirmation} />
        </Menu.Group>
        <Menu.Group title="Delivery Receipt">
          <MenuItem label="XPO" documentTemplate={DocumentTemplate.XPODeliveryReceipt} />
        </Menu.Group>
        <Menu.Group title="Accounting">
          <MenuItem label="Invoice" documentTemplate={DocumentTemplate.Invoice} />
          <MenuItem
            label="Carrier Earnings Report"
            documentTemplate={DocumentTemplate.CarrierEarningsReport}
            badge={<ComingSoonBadge />}
          />
        </Menu.Group>
        <Menu.Divider />
        <Menu.Group title="Components">
          <MenuItem label="Table" documentTemplate={DocumentTemplate.Table} />
        </Menu.Group>
      </Menu>

      {/** BOTTOM BOX */}
      <Pane
        // borderTop={`1px solid ${theme.colors.border.default}`}
        width={'100%'}
        padding={16}
      >
        <Button marginRight={16} intent="danger" onClick={logout} iconBefore={LogOutIcon}>
          Sign out
        </Button>
      </Pane>
    </Pane>
  )
}
