import { Text as RenderText, StyleSheet } from '@react-pdf/renderer'
import { Column, Row } from '../Pane'
import { Text } from '../Text'

const styles = StyleSheet.create({
  subtitle: {
    fontStyle: 'bold',
    fontSize: 9,
    marginTop: -1,
    marginBottom: 1,
  },

  legal: {
    fontSize: 7,
  },

  title: {
    fontStyle: 'bold',
    fontSize: 12,
    top: 0,
    left: 0,
    width: '100%',
    textAlign: 'left',
    background: 'transparent',
  },

  pageNumber: {
    minHeight: 30,
    fontSize: 14,
    textAlign: 'center',
    background: 'transparent',
  },

  serviceTypeBanner: {
    left: -24,
    width: '100%',
    textAlign: 'right',
    background: 'transparent',
  },
})

interface BillOfLadingHeaderProps {
  serviceLevelStatement?: string
  isGuaranteed?: boolean
  isSameDay?: boolean
  isNextDay?: boolean
}

const _getServiceType = (props: BillOfLadingHeaderProps): string | null => {
  if (props.serviceLevelStatement) {
    return props.serviceLevelStatement.toUpperCase()
  } else if (props.isGuaranteed) {
    return 'GUARANTEED'
  } else if (props.isSameDay) {
    return 'SAME DAY'
  } else if (props.isNextDay) {
    return 'NEXT DAY'
  }

  return null
}

const _getServiceTypeFontSize = (props: BillOfLadingHeaderProps): number => {
  // NOTE [jdao]: 10 is the length of 'guaranteed'
  if (props.serviceLevelStatement && props.serviceLevelStatement.length > 10) {
    return 12
  }

  return 22
}

export const BillOfLadingHeader = (props: BillOfLadingHeaderProps): JSX.Element => {
  return (
    <Column justify-content="flex-start" align-items="flex-start">
      <Row justifyContent="space-between">
        <Column flex={1}>
          <Text {...styles.title}>Straight Bill of Lading</Text>
          <Text {...styles.subtitle}>Short Form, Original & Not Negotiable</Text>
        </Column>
        {/* 
          NOTE [jdao]: This will not show on page 2 / be covered by any elements 
          that get pushed to the next page. In order for this to not occur, we need
          to move this outside of a <View>, but then it pushes elements down.
        */}
        <Column flex={1}>
          <RenderText
            style={{ ...styles.pageNumber }}
            render={({ subPageNumber, subPageTotalPages }) => `Page ${subPageNumber} of ${subPageTotalPages}`}
            fixed
          />
        </Column>
        <Column flex={1}>
          <Text bold {...styles.serviceTypeBanner} fontSize={_getServiceTypeFontSize(props)}>
            {_getServiceType(props)}
          </Text>
        </Column>
      </Row>
      <Row>
        <Text {...styles.legal}>
          This form contains only the information necessary for the motor carrier to deliver, rate, and invoice the
          shipment described below.
        </Text>
      </Row>
    </Column>
  )
}
