import { WaypointType } from '@mothership/shipment-types/models'
import { Page, StyleSheet } from '@react-pdf/renderer'
import moment from 'moment'
import { DefaultFooter } from '../components/DefaultFooter'
import { Pane } from '../components/Pane'
import { PDFWrapper } from '../components/PDFWrapper'
import { RateConfirmationCarrierAgreement } from '../components/RateConfirmationCarrierAgreement'
import { RateConfirmationCarrierPayment } from '../components/RateConfirmationCarrierPayment'
import { RateConfirmationCarrierSignature } from '../components/RateConfirmationCarrierSignature'
import { RateConfirmationHeader } from '../components/RateConfirmationHeader'
import { RateConfirmationStop } from '../components/RateConfirmationStop'
import { Text } from '../components/Text'
import { DocumentType } from './DocumentType'
import { RateConfirmationDocumentGenerationData } from '@mothership/document-types'

const styles = StyleSheet.create({
  page: {
    padding: 32,
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontSize: 7,
  },
})

export const RateConfirmation: DocumentType<RateConfirmationDocumentGenerationData> = ({
  renderValues: rate,
}: {
  renderValues: RateConfirmationDocumentGenerationData
}) => {
  const pickupWaypoint = rate.shipment.waypoints?.find((waypoint) => waypoint.type === WaypointType.Pickup)
  const deliveryWaypoint = rate.shipment.waypoints?.find((waypoint) => waypoint.type === WaypointType.Delivery)

  return (
    <PDFWrapper>
      <Page size="A4" style={styles.page}>
        <RateConfirmationHeader
          dateString={moment().format('MMMM D, YYYY')}
          loadNumber={rate.shipment.referenceNumber}
        />
        <Pane direction="column" flex={1} width={'100%'}>
          {pickupWaypoint ? (
            <RateConfirmationStop
              stopNumber={1}
              waypoint={pickupWaypoint}
              freight={rate.shipment.freight ?? []}
              stopType={WaypointType.Pickup}
            />
          ) : null}
          {deliveryWaypoint ? (
            <RateConfirmationStop
              stopNumber={2}
              waypoint={deliveryWaypoint}
              freight={rate.shipment.freight ?? []}
              stopType={WaypointType.Delivery}
            />
          ) : null}
        </Pane>
        <DefaultFooter />
      </Page>
      <Page size="A4" style={styles.page}>
        <RateConfirmationHeader
          dateString={moment().format('MMMM D, YYYY')}
          loadNumber={rate.shipment.referenceNumber}
        />
        <Pane direction="column" flex={1} width={'100%'}>
          <Pane direction="column" borderBottom={'1px solid #000000'} width={'100%'} paddingBottom={16}>
            <SectionHeader>Carrier payment</SectionHeader>
            <RateConfirmationCarrierPayment
              miles={rate.shipment.miles}
              terms="Net 15"
              payoutAmountCents={rate.payoutAmountCents}
              driver={rate.driver}
            />
          </Pane>
          <SectionHeader>Carrier agreement</SectionHeader>
          <RateConfirmationCarrierAgreement />
          <Pane flex={1} />
          <Pane
            direction="column"
            border={'1px solid #cbcbcb'}
            paddingLeft={16}
            paddingRight={16}
            paddingBottom={16}
            marginBottom={16}
            width={'100%'}
          >
            <SectionHeader>Carrier signature</SectionHeader>
            <RateConfirmationCarrierSignature />
          </Pane>
        </Pane>
        <DefaultFooter displaySMSAndEmailContact />
      </Page>
    </PDFWrapper>
  )
}

const SectionHeader = ({ children }: { children: string | string[] }): JSX.Element => (
  <Text bold paddingTop={12} paddingBottom={12} fontSize={12} borderBottom={'1px solid #cbcbcb'} width={'100%'}>
    {children}
  </Text>
)
