import { Document } from '@react-pdf/renderer'

interface PDFWrapperProps {
  children: JSX.Element | Array<JSX.Element>
}

export const PDFWrapper = (props: PDFWrapperProps): JSX.Element => {
  const { children } = props
  return <Document>{children}</Document>
}
