import { centsToCurrencyString } from '../utils/currency'
import { formatPhoneNumberToNational } from '../utils/string'
import { Pane } from './Pane'
import { Text } from './Text'

const BORDER = '1px solid #CBCBCB'

interface RateConfirmationCarrierPaymentProps {
  miles: number
  payoutAmountCents: number
  terms: 'Net 7' | 'Net 15' | 'Net 30'
  driver: {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    dotNumber: string
  }
}
export const RateConfirmationCarrierPayment = (props: RateConfirmationCarrierPaymentProps): JSX.Element => {
  const { miles, payoutAmountCents, terms, driver } = props
  return (
    <Pane direction={'column'} width={'100%'}>
      <Pane paddingTop={8} width={'100%'}>
        <Pane direction="column" width={194}>
          <Text bold>
            {driver.firstName} {driver.lastName}
          </Text>
          <DescriptiveText>{driver.email}</DescriptiveText>
          <DescriptiveText>{formatPhoneNumberToNational(driver.phoneNumber)}</DescriptiveText>
          <DescriptiveText>USDOT: {driver.dotNumber}</DescriptiveText>
        </Pane>
        <DataSection label="Trip miles" value={miles.toLocaleString('en-US') + ' mi'} flex={1} />
        <DataSection label="Rate per mile" value={centsToCurrencyString(payoutAmountCents / miles)} flex={1} />
        <DataSection label="Terms" value={terms} flex={1} />
        <DataSection
          label="Total payment"
          value={centsToCurrencyString(payoutAmountCents)}
          flex={1}
          textAlignRight={true}
        />
      </Pane>
      <Text bold width={'100%'} textAlign="right" paddingTop={16}>
        Pays instantly upon delivery if bank account is connected via mobile app
      </Text>
      <Text width={'100%'} textAlign="right" fontSize={7} color={'#545454'} paddingTop={2}>
        Otherwise, send invoices to: accounting@mothership.com
      </Text>
    </Pane>
  )
}

const DescriptiveText = ({
  children,
  flex,
  width,
}: {
  children: string | JSX.Element | JSX.Element[] | undefined | string[]
  flex?: number
  width?: string
}): JSX.Element => (
  <Text color={'#757575'} paddingTop={1} flex={flex} width={width}>
    {children}
  </Text>
)

const DataSection = ({
  label,
  value,
  flex,
  textAlignRight,
}: {
  label: string
  value: string
  flex: number
  textAlignRight?: boolean
}) => {
  return (
    <Pane direction="column" flex={flex}>
      <Text bold paddingBottom={8} width={'100%'} textAlign={textAlignRight ? 'right' : undefined}>
        {label}
      </Text>
      <Text borderTop={BORDER} paddingTop={8} width={'100%'} textAlign={textAlignRight ? 'right' : undefined}>
        {value}
      </Text>
    </Pane>
  )
}
