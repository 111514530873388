import { Pane } from './Pane'
import { Text } from './Text'

export const XPODeliveryReceiptDisclaimer = (): JSX.Element => {
  return (
    <Pane width={'100%'} padding={2} backgroundColor={'white'} borderBottom={'1px solid #000'}>
      <Text>SUBJECT TO TERMS AND CONDITIONS HEREIN, AND TARIFF CNWY-199 IN EFFECT ON DATE OF SHIPMENT.</Text>
    </Pane>
  )
}
