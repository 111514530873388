import { Font } from '@react-pdf/renderer'
import { Courier } from './Courier'
import { Handwritten } from './Handwritten'
import { HelveticaNeue } from './HelveticaNeue'
import { HelveticaNeueBold } from './HelveticaNeueBold'
import { Signature } from './Signature'

export const registerFonts = (): void => {
  //Helvetica Neue
  Font.register({
    family: 'Helvetica Neue',
    fonts: [
      {
        fontStyle: 'normal',
        src: HelveticaNeue,
      },
      {
        fontStyle: 'bold',
        src: HelveticaNeueBold,
      },
    ],
  })

  // Courier
  Font.register({
    family: 'Courier',
    fonts: [
      {
        fontStyle: 'normal',
        src: Courier,
      },
    ],
  })

  // Handwritten
  Font.register({
    family: 'Handwritten',
    fonts: [
      {
        fontStyle: 'normal',
        src: Handwritten,
      },
    ],
  })

  // Signature
  Font.register({
    family: 'Signature',
    fonts: [
      {
        fontStyle: 'normal',
        src: Signature,
      },
    ],
  })
}
