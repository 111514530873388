import { Text as RenderText } from '@react-pdf/renderer'
import {
  MOTHERSHIP_BILLING_ADDRESS_CITY_STATE_ZIP,
  MOTHERSHIP_BILLING_ADDRESS_NAME,
  MOTHERSHIP_BILLING_ADDRESS_STREET,
} from '../utils/billing'
import { Pane } from './Pane'
import { Text } from './Text'

interface DefaultFooterProps {
  fixed?: boolean
  displaySMSAndEmailContact?: boolean
}

const TEXT_COLOR = '#545454'
const MOTHERSHIP_ADDRESS =
  `${MOTHERSHIP_BILLING_ADDRESS_NAME}, ` +
  `${MOTHERSHIP_BILLING_ADDRESS_STREET}, ` +
  `${MOTHERSHIP_BILLING_ADDRESS_CITY_STATE_ZIP}`
export const DefaultFooter = (props: DefaultFooterProps): JSX.Element => {
  const { fixed = false, displaySMSAndEmailContact } = props
  return (
    <Pane fixed={fixed} borderTop={'2px solid #000000'} paddingTop={8}>
      <Pane justifyContent="space-between" alignItems="flex-start" width={'100%'}>
        <Pane direction="column">
          <Text bold color={TEXT_COLOR} fontSize={7}>
            Need help?
          </Text>
          <Text color={TEXT_COLOR} paddingTop={3} fontSize={7}>
            {displaySMSAndEmailContact
              ? 'Text (310) 905-8677 or email: support@mothership.com'
              : 'Visit www.mothership.com/help'}
          </Text>
          <Text> </Text>
          <RenderText
            style={{
              fontSize: '7px',
              color: '#545454',
              minHeight: '12px',
            }}
            fixed
            render={({ pageNumber }) => `Page ${pageNumber}`}
          />
        </Pane>
        <Pane alignItems="flex-end" direction="column">
          <Text color={TEXT_COLOR} fontSize={7}>
            {MOTHERSHIP_ADDRESS}
          </Text>
          <Text color={TEXT_COLOR} paddingTop={3} fontSize={7}>
            Tax ID: 82-1104818
          </Text>
          <Text color={TEXT_COLOR} paddingTop={3} fontSize={7}>
            D-U-N-S: 080675167
          </Text>
          <Text color={TEXT_COLOR} paddingTop={3} fontSize={7}>
            USDOT: 3029631
          </Text>
        </Pane>
      </Pane>
    </Pane>
  )
}
