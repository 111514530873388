import { Pane } from './Pane'

const BORDER = '1px solid #000'

export enum XPODeliveryReceiptTableColumnSize {
  PCS = 23,
  HM = 23,
  WEIGHT = 65,
  RATE = 65,
  CHARGES = 81,
}

export const XPODeliveryReceiptTable = (): JSX.Element => {
  return (
    <Pane width={'100%'} height={'100%'}>
      <Pane width={XPODeliveryReceiptTableColumnSize.PCS} borderRight={BORDER} />
      <Pane width={XPODeliveryReceiptTableColumnSize.HM} borderRight={BORDER} />
      <Pane flex={1} borderRight={BORDER} />
      <Pane width={XPODeliveryReceiptTableColumnSize.WEIGHT} borderRight={BORDER} />
      <Pane width={XPODeliveryReceiptTableColumnSize.RATE} borderRight={BORDER} />
      <Pane width={XPODeliveryReceiptTableColumnSize.CHARGES} />
    </Pane>
  )
}
