import {
  BillOfLadingFreightPieceType,
  BillOfLadingFreightType,
  BillOfLadingParams,
  BillOfLadingShipmentType,
} from '@mothership/document-types'
import { ShippingLabel } from './ShippingLabel.data'

export const BillOfLading: BillOfLadingParams = {
  shipment: {
    freight: [
      {
        id: '241e1775-f5b3-4490-9ba8-ff5024df7565',
        description: 'Cheese',
        freightClass: 30,
        height: 40,
        length: 42,
        quantity: 1,
        type: BillOfLadingFreightType.Pallet,
        weight: 50,
        width: 48,
      },
      {
        id: '241e1775-f5b3-4490-9ba8-ff5024df7566',
        description: 'Pizzas',
        freightClass: 97.5,
        height: 60,
        length: 40,
        pieceCount: 4,
        pieceType: BillOfLadingFreightPieceType.Box,
        quantity: 1,
        type: BillOfLadingFreightType.Pallet,
        weight: 145,
        width: 48,
      },
      {
        id: '241e1775-f5b3-4490-9ba8-ff5024df7567',
        description: 'Dangerous Pizzas',
        freightClass: 250,
        height: 60,
        length: 40,
        quantity: 5,
        type: BillOfLadingFreightType.Crate,
        weight: 5,
        width: 48,
        isHazmat: true,
        hazmatEmergencyContactFirstName: 'John',
        hazmatEmergencyContactLastName: 'Smith',
        hazmatEmergencyContactPhoneNumber: '+18005555555',
        hazmatHazardClass: 'Class Seven',
        hazmatPackageCount: 1,
        hazmatPackageType: 'Box',
        hazmatPackingGroup: 'III',
        hazmatProperShippingName: 'Dangerous Pizza',
        hazmatUnUaId: '91554',
      },
    ],
    deliveryLocation: {
      city: 'Phoenix',
      name: 'JAY MCNULTY',
      notes:
        'ANY ADDITIONAL ACCESSORIALS NEED PRIOR APPROVAL -CURBSIDE DELIVERY ONLY, "NO" INSIDE DELIVERY *CALL FOR DELIVERY APPT, SIGNATURE ON POD REQUIRED, LIFTGATE REQD, DO NOT DOUBLE STACK',
      phoneNumber: '+13109058677',
      closeTime: '4:00pm',
      openTime: '8:00am',
      referenceNumber: 'delivery reference number: 889205050',
      state: 'AZ',
      streetAddress1: '2940 West Willetta Street',
      timezone: 'America/Phoenix',
      zip: '85009',
      accessorials: {
        liftgate: true,
        localInsideDelivery: true,
      },
      fulfillmentByAmazonId: '8N7EGXAD',
      purchaseOrderNumber: '123456789',
    },
    deliveryEtaAt: '2020-09-06T16:00:00-07:00',
    pickedUpAt: '2020-09-03T17:00:12.524Z',
    freightReadyTimeAt: '2020-09-03T16:00:00.000Z',
    pickupLocation: {
      city: 'Phoenix',
      name: 'XPO Logistics',
      notes: 'PU REF 104109, FOR ANY CARRIER RELATED ISSUE CALL 310-555-5555',
      phoneNumber: '+18007825036',
      closeTime: '4:00pm',
      openTime: '8:00am',
      referenceNumber: 'pickup reference number: 12345678',
      state: 'AZ',
      streetAddress1: '3450 South 59th Avenue',
      streetAddress2: 'Unit A',
      timezone: 'America/Phoenix',
      zip: '85043',
      accessorials: {
        liftgate: true,
      },
    },
    referenceNumber: 'MS7875351',
    isSameDay: true,
    // isGuaranteed: true,
    // serviceLevelStatement: 'Time Critical due 12/15 by 12pm',
    // serviceLevelStatement: 'GSP PM',
    // serviceLevelStatement: 'LTL Guaranteed',

    type: BillOfLadingShipmentType.Direct,
    // type: BillOfLadingShipmentType.Integrated,

    hasDeliveryInspectionPhotos: true,
    pickupInspectionNotes: "Don't drop the pizzas.",
  },
  carrierUser: {
    firstName: 'Jane',
    lastName: 'Smith',
  },
  carrierInfo: {
    // bolNumber: '1234567890',
    carrierName: 'XPO Logistics',
    pickupNumber: '1234567890',
    proNumber: '1234567890',
    quoteId: '1234567890',
  },
  pickupSignatorName: 'Oprah Winfrey',
  pickupSignature: {
    url: 'https://i.imgur.com/2uoWu00.png',
    createdAt: '2023-02-09T17:00:00Z',
  },
  deliverySignatorName: 'Issac Newton',
  deliverySignature: {
    url: 'https://i.imgur.com/pC3fdo9.png',
    createdAt: '2023-02-09T17:00:00Z',
  },

  shippingLabelParams: ShippingLabel,
}
