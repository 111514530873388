import { moneyUtils } from '@mothership/utils-ts'

interface ICentsToCurrencyOptions {
  locale?: string
}
type ICentsToCurrency = (cents: number, options?: ICentsToCurrencyOptions) => string

export const centsToCurrencyString: ICentsToCurrency = (cents, options): string => {
  const dollars = moneyUtils.centsToDollars(cents)
  const formatter = new Intl.NumberFormat(options?.locale ?? 'en-US', {
    minimumFractionDigits: 2,
  })
  const possibleNegative = cents < 0 ? '-' : ''
  return `${possibleNegative}$${formatter.format(dollars)}`
}
