import { BillOfLadingLocation } from '@mothership/document-types'
import { Column, Row } from '../Pane'
import { Text } from '../Text'
import { BOL_DEFAULT_MARGIN, BOL_DEFAULT_PADDING } from './BillOfLadingDefaults'

interface BillOfLadingAccessorialsProps {
  pickupLocation: BillOfLadingLocation
  deliveryLocation: BillOfLadingLocation
}

export const BillOfLadingAccessorials = ({
  pickupLocation,
  deliveryLocation,
}: BillOfLadingAccessorialsProps): JSX.Element | null => {
  const response = []

  const pickupAccessorials = pickupLocation?.accessorials ?? {}
  const deliveryAccessorials = deliveryLocation?.accessorials ?? {}

  // LIFTGATE
  if (pickupAccessorials.liftgate && deliveryAccessorials.liftgate) {
    response.push('LIFT GATE PICK-UP & DELIVERY')
  } else if (pickupAccessorials.liftgate && !deliveryAccessorials.liftgate) {
    response.push('LIFT GATE PICK-UP ONLY')
  } else if (!pickupAccessorials.liftgate && deliveryAccessorials.liftgate) {
    response.push('LIFT GATE DELIVERY ONLY')
  }

  // RESIDENTIAL
  if (pickupAccessorials.residential && deliveryAccessorials.residential) {
    response.push('RESIDENTIAL PICKUP & DELIVERY')
  } else if (pickupAccessorials.residential && !deliveryAccessorials.residential) {
    response.push('RESIDENTIAL PICKUP')
  } else if (!pickupAccessorials.residential && deliveryAccessorials.residential) {
    response.push('RESIDENTIAL DELIVERY')
  }

  // APPOINTMENT
  const pickupHasAppointment = pickupAccessorials.appointment || Boolean(pickupLocation.appointment)
  const deliveryHasAppointment = deliveryAccessorials.appointment || Boolean(deliveryLocation.appointment)
  if (pickupHasAppointment && deliveryHasAppointment) {
    response.push('PICKUP & DELIVERY APPOINTMENT REQUIRED')
  } else if (pickupHasAppointment && !deliveryHasAppointment) {
    response.push('PICKUP APPOINTMENT REQUIRED')
  } else if (!pickupHasAppointment && deliveryHasAppointment) {
    response.push('DELIVERY APPOINTMENT REQUIRED')
  }

  // INSIDE
  if (pickupAccessorials.inside && deliveryAccessorials.inside) {
    response.push('LTL INSIDE PICKUP & DELIVERY')
  } else if (pickupAccessorials.inside && !deliveryAccessorials.inside) {
    response.push('LTL INSIDE PICKUP')
  } else if (!pickupAccessorials.inside && deliveryAccessorials.inside) {
    response.push('LTL INSIDE DELIVERY')
  }

  // INSIDE LOCAL
  if (deliveryAccessorials.localInsideDelivery) {
    response.push('LOCAL INSIDE DELIVERY')
  }

  // TRADESHOW
  if (pickupAccessorials.tradeshow && deliveryAccessorials.tradeshow) {
    response.push('TRADESHOW PICKUP & DELIVERY')
  } else if (pickupAccessorials.tradeshow && !deliveryAccessorials.tradeshow) {
    response.push('TRADESHOW PICKUP')
  } else if (!pickupAccessorials.tradeshow && deliveryAccessorials.tradeshow) {
    response.push('TRADESHOW DELIVERY')
  }

  // LIMITED ACCESS
  if (pickupAccessorials.limitedAccess && deliveryAccessorials.limitedAccess) {
    response.push('LIMITED ACCESS PICKUP & DELIVERY')
  } else if (pickupAccessorials.limitedAccess && !deliveryAccessorials.limitedAccess) {
    response.push('LIMITED ACCESS PICKUP')
  } else if (!pickupAccessorials.limitedAccess && deliveryAccessorials.limitedAccess) {
    response.push('LIMITED ACCESS DELIVERY')
  }

  // SORT OR SEGREGATE
  if (pickupAccessorials.sortSeg && deliveryAccessorials.sortSeg) {
    response.push('SORT/SEGREGATE PICKUP & DELIVERY')
  } else if (pickupAccessorials.sortSeg && !deliveryAccessorials.sortSeg) {
    response.push('SORT/SEGREGATE PICKUP')
  } else if (!pickupAccessorials.sortSeg && deliveryAccessorials.sortSeg) {
    response.push('SORT/SEGREGATE DELIVERY')
  }

  // CFS
  if (pickupAccessorials.cfs && deliveryAccessorials.cfs) {
    response.push('CFS PICKUP & DELIVERY')
  } else if (pickupAccessorials.cfs && !deliveryAccessorials.cfs) {
    response.push('CFS PICKUP')
  } else if (!pickupAccessorials.cfs && deliveryAccessorials.cfs) {
    response.push('CFS DELIVERY')
  }

  // ALCOHOL
  if (pickupAccessorials.alcohol || deliveryAccessorials.alcohol) {
    response.push('ALCOHOL')
  }

  return response.length > 0 ? (
    <Column
      backgroundColor={'#000000'}
      padding={BOL_DEFAULT_PADDING}
      width={'100%'}
      marginTop={BOL_DEFAULT_MARGIN}
      minHeight={71}
      justifyContent="center"
    >
      {response.map((accessorial) => (
        <Row>
          <Text bold color={'#ffffff'} fontSize={11} key={accessorial}>
            {accessorial}
          </Text>
        </Row>
      ))}
    </Column>
  ) : null
}
