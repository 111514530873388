export const BOL_DEFAULT_PADDING = 5

export const BOL_DEFAULT_MARGIN = BOL_DEFAULT_PADDING * 2

export const BOL_DEFAULT_BORDER = '1px solid #000000'

export const BOL_DEFAULT_HEADER_PADDING = {
  paddingLeft: BOL_DEFAULT_PADDING,
  paddingTop: 2,
  paddingRight: BOL_DEFAULT_PADDING,
  paddingBottom: 2,
}
