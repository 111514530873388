import { TableDocumentRenderValues } from '@mothership/document-types'
import { Document, Page, Text, View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import { PDFWrapper } from '../components/PDFWrapper'
import { DocumentType } from './DocumentType'

type ArrayOfObjects = TableDocumentRenderValues<Record<string, string>>

export const Table: DocumentType<ArrayOfObjects> = ({
  renderValues: table,
}: {
  renderValues: TableDocumentRenderValues<Record<string, string>>
}) => {
  if (table.length === 0)
    return (
      <PDFWrapper>
        <Page size="A4" />
      </PDFWrapper>
    )

  const pageStyle: Style = {
    paddingTop: 10,
    paddingHorizontal: 10,
    paddingBottom: 10,
  }

  const tableStyle: Style = {
    display: 'flex',
    width: 'auto',
  }

  const tableRowStyle: Style = {
    flexDirection: 'row',
  }

  const firstTableColHeaderStyle: Style = {
    width: '20%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderBottomWidth: 1,
    backgroundColor: '#bdbdbd',
    margin: 0,
    padding: 0,
  }

  const firstTableColStyle: Style = {
    width: '20%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderTopWidth: 1,
    margin: 0,
    padding: 0,
  }

  const columnNames = Object.keys(table[0])
  const fontSize = Math.max(1, Math.min(12, 50 / columnNames.length))
  const numRowsPerPage = Math.max(Math.round(columnNames.length * 3.2), 25)
  const tableCellHeaderStyle: Style = {
    textAlign: 'center',
    margin: 0,
    padding: 0,
    fontSize: fontSize,
    fontWeight: 'bold',
  }

  const tableCellStyle: Style = {
    textAlign: 'center',
    margin: 0,
    padding: 0,
    fontSize: fontSize,
  }

  const renderTableHeader = (table: ArrayOfObjects) => {
    const columnNames = Object.keys(table[0])
    return (
      <View style={tableRowStyle} fixed>
        {columnNames.map((columnName) => (
          <View style={firstTableColHeaderStyle} key={columnName}>
            <Text style={tableCellHeaderStyle}>{columnName}</Text>
          </View>
        ))}
      </View>
    )
  }

  const renderTableRow = (tableRow: ArrayOfObjects[number]) => {
    return (
      <View style={tableRowStyle}>
        {Object.keys(tableRow).map((key) => (
          <View style={firstTableColStyle} key={key}>
            <Text style={tableCellStyle}>{tableRow[key]}</Text>
          </View>
        ))}
      </View>
    )
  }

  const rowsChunkedByPage = []
  let curChunk = []
  for (const row of table) {
    if (curChunk.length === numRowsPerPage) {
      rowsChunkedByPage.push(curChunk)
      curChunk = []
    } else {
      curChunk.push(row)
    }
  }
  if (curChunk.length) {
    rowsChunkedByPage.push(curChunk)
  }

  return (
    <Document>
      {rowsChunkedByPage.map((chunk, index) => (
        <Page style={pageStyle} size="A4" orientation="landscape" key={index}>
          <View style={tableStyle}>
            {index === 0 ? renderTableHeader(chunk) : null}
            {chunk.map((row) => renderTableRow(row))}
          </View>
        </Page>
      ))}
    </Document>
  )
}
