import {
  DestinationType,
  DocumentTemplate,
  FileType,
  GenerateInvoiceRequest,
  InvoiceDocumentGenerationData,
} from '@mothership/document-types'
import {
  ContactDto,
  ContactType,
  CreditSource,
  CreditType,
  InvoiceDto,
  InvoiceDtoStatus,
  InvoiceOwnerType,
  LineItemType,
  PaymentSourceType,
  PaymentStatus,
  PayoutSource,
} from '@mothership/finance/dist/api'
import { ShipmentInvoiceMetadata } from '@mothership/shipment-types/accounting'
import { FreightType, WaypointType } from '@mothership/shipment-types/models'

export const TEST_MINIO_BUCKET = 'test-bucket'

export const contact: ContactDto = {
  amountDueCents: 0,
  createdAt: 1601333886000,
  email: 'contant@mothership.com',
  id: 'FAKE_CONTACT_ID',
  netsuiteId: null,
  organizationFirebaseKey: '-FAKE_ORG',
  organizationName: 'Acme Systems, Inc.',
  payoutSource: PayoutSource.Stripe,
  phoneNumber: '+13109058677',
  syncedWithNetSuiteAt: null,
  term: {
    availableCreditCents: 0,
    createdAt: 1601333886000,
    creditLimitCents: 9999999,
    id: 'FAKE_TERM_ID',
    lengthDays: 15,
    totalAmountDueCents: 0,
    updatedAt: 1601333886000,
  },
  type: ContactType.AccountsReceivable,
  updatedAt: 1601333886000,
}

const shipmentInvoiceMetadata: ShipmentInvoiceMetadata = {
  freight: [
    {
      type: FreightType.Pallet,
      weight: 100,
      quantity: 2,
    },
    {
      type: FreightType.Box,
      weight: 420,
      quantity: 3,
    },
  ],
  version: '1',
  waypoints: [
    {
      city: 'Los Angeles',
      type: WaypointType.Pickup,
      state: 'CA',
      zipCode: '42020',
      locationName: "McDonald's",
      streetAddress1: '123 Jump Street',
      shipperReferenceNumber: '1234',
      shipperNotes: 'Have a good drive!',
    },
    {
      city: 'Los Angeles',
      type: WaypointType.Delivery,
      state: 'CA',
      zipCode: '42123',
      locationName: "Jacob's House",
      streetAddress1: '321 Delivery Street',
      shipperReferenceNumber: '12978',
      shipperNotes: null,
    },
  ],
  description: 'Very cool description',
  referenceNumber: 'MS7893847',
  routeReferenceNumber: null,
  shipperUserName: 'Chad Jones',
  purchaseOrderNumber: '1234',
  proNumber: 'awesomeProNumberWoot!',
}

const invoiceId = '3fb1b206-ce5c-46b4-b076-ea7c2f95c60f'
const invoiceDto: InvoiceDto = {
  breakdown: {
    creditAmountCents: 15000,
    paymentAmountCents: 10000,
    subtotalCents: 40000,
    taxAmountCents: 0,
    refundAmountCents: 0,
  },
  chargeFirebaseKey: null,
  contact,
  createdAt: 1600977729458,
  credits: [
    {
      amountCents: 10000,
      createdAt: 1600977729422,
      description: 'Mothership credit',
      id: '6f680401-551e-4b95-a47b-dd2ffd31252d',
      invoiceId,
      netsuiteId: null,
      reason: 'Some reason',
      source: CreditSource.MothershipCredit,
      syncedWithNetSuiteAt: null,
      type: CreditType.CreditNoteAllocation,
      updatedAt: 1600977729422,
    },
    {
      amountCents: 5000,
      createdAt: 1600977729422,
      description: 'Late delivery',
      id: '6f680401-551e-4b95-a47b-dd2ffd31252d',
      invoiceId,
      netsuiteId: null,
      reason: 'Some reason',
      source: CreditSource.MothershipCredit,
      syncedWithNetSuiteAt: null,
      type: CreditType.Refund,
      updatedAt: 1600977729422,
    },
  ],
  dueBy: 1600977729515,
  id: invoiceId,
  lineItems: [
    {
      createdAt: 1600977729430,
      description: 'Base shipment',
      discountAmountCents: 0,
      id: 'f9a9ff36-463b-4803-98ab-a08ca1e95e92',
      invoiceId,
      quantity: 1,
      taxAmountCents: 0,
      totalAmountCents: 40000,
      type: LineItemType.BaseShipment,
      unitAmountCents: 40000,
      updatedAt: 1600977729430,
    },
  ],
  metadata: shipmentInvoiceMetadata,
  netsuiteId: null,
  ownerId: '3fb1b206-ce55-46b4-b076-ea7c2f95c60f',
  ownerType: InvoiceOwnerType.Shipment,
  payments: [
    {
      amountCents: 10000,
      brand: 'Visa',
      bulkReconcileRequestId: '',
      createdAt: 1600977729447,
      id: '3b7bebf6-5317-4d5b-8457-b4b9bc8ffa49',
      invoiceId,
      last4: '1234',
      netsuiteId: null,
      receivedAt: 1651734000000,
      refundableAmountCents: 10000,
      refundApplyList: [],
      refundedAmountCents: 0,
      sourceType: PaymentSourceType.Card,
      status: PaymentStatus.Succeeded,
      stripeChargeId: 'fake-stripe-charge-id',
      syncedWithNetSuiteAt: null,
      updatedAt: 1600977729447,
    },
    {
      amountCents: 10000,
      brand: 'Visa',
      bulkReconcileRequestId: '',
      createdAt: 1600977729447,
      id: '3b7bebf6-5317-4d5b-8457-b4b9bc8ffa48',
      invoiceId,
      last4: '1234',
      netsuiteId: null,
      receivedAt: 1651734000000,
      refundableAmountCents: 0,
      refundApplyList: [],
      refundedAmountCents: 0,
      sourceType: PaymentSourceType.AchCreditTransfer,
      status: PaymentStatus.Failed,
      stripeChargeId: 'fake-stripe-charge-id',
      syncedWithNetSuiteAt: null,
      updatedAt: 1600977729447,
    },
  ],
  referenceNumber: 'MS123456',
  remainingAmountDueCents: 20000,
  status: InvoiceDtoStatus.Due,
  syncedWithNetSuiteAt: null,
  totalAmountCents: 30000,
  updatedAt: 1600977729458,
}

export const createGenerateInvoiceRequest = (fileType: FileType): GenerateInvoiceRequest => {
  let fileExtension = '.pdf'
  switch (fileType) {
    case FileType.Pdf:
      fileExtension = '.pdf'
      break
    case FileType.Netsuite:
    case FileType.Quickbooks:
    case FileType.Xero:
      fileExtension = '.csv'
      break
    case FileType.Zip:
      throw new Error(`zip cannot be used for a single generate document request!`)
  }

  const generateInvoiceRequest: GenerateInvoiceRequest = {
    async: false,
    destination: {
      type: DestinationType.File,
      metadata: {
        filename: `${new Date().valueOf()}${fileExtension}`,
      },
    },
    fileType,
    renderValues: invoiceDto,
    template: DocumentTemplate.Invoice,
  }
  return generateInvoiceRequest
}

export const Invoice: InvoiceDocumentGenerationData = invoiceDto
