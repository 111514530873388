import { XPODeliveryReceiptDocumentGenerationData as DRGenerationData } from '@mothership/document-types'
import { Image } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'

import { Fonts } from '../style'
import { Pane } from './Pane'
import { Text } from './Text'
import { XPODeliveryReceiptDisclaimer } from './XPODeliveryReceiptDisclaimer'
import { XPODeliveryReceiptTableColumnSize } from './XPODeliveryReceiptTable'

const BORDER = '1px solid #000'
type XPODeliveryReceiptFooterProps = DRGenerationData['delivery']

export const XPODeliveryReceiptFooter = (props: XPODeliveryReceiptFooterProps): JSX.Element => {
  return (
    <Pane
      width={'100%'}
      direction="column"
      wrap={false}
      borderLeft={BORDER}
      borderRight={BORDER}
      flex={1}
      justifyContent="flex-end"
    >
      <Pane width={'100%'} height={65} borderBottom={BORDER} backgroundColor={'white'} borderTop={BORDER}>
        <Pane width={80} height={'100%'}>
          <Received />
        </Pane>
        <Pane direction="column" flex={1}>
          <Pane width={'100%'} flex={1}>
            <ShrinkWrap intact={props.shrinkWrapIntact} />
            <Delivered pieceCount={props.pieceCount} />
            <Time hour={props.hour} minute={props.minute} />
            <DriverSignature label={'DRIVER SIGNATURE'} name={props.driverName} />
            <Date day={props.day} month={props.month} year={props.year} />
          </Pane>
          <Pane width={'100%'} flex={1} borderTop={BORDER}>
            <Accessorials {...props.accessorials} />
            <Signature label={'CONSIGNEE SIGNATURE'} src={props.consigneeSignatureUrl} />
            <ConsigneeName consigneeName={props.consigneeName} />
            <Date day={props.day} month={props.month} year={props.year} />
          </Pane>
        </Pane>
      </Pane>
      <XPODeliveryReceiptDisclaimer />
    </Pane>
  )
}

const Box = ({
  label,
  children,
  last,
  width,
  flex,
}: {
  label: string
  children?: JSX.Element | JSX.Element[]
  last?: boolean
  width?: Style['width']
  flex?: number
}): JSX.Element => {
  return (
    <Pane direction="column" padding={2} borderRight={last ? undefined : BORDER} width={width} flex={flex}>
      <Text bold marginBottom={2}>
        {label}
      </Text>
      {children}
    </Pane>
  )
}

const Received = (): JSX.Element => {
  return (
    <Box label={'RECEIVED'} width={120}>
      <Text textAlign="right" fontSize={6}>{`PIECES ABOVE.\nDESCRIBED FREIGHT\nIN GOOD ORDER\nEXCEPT AS NOTED.`}</Text>
    </Box>
  )
}

const ShrinkWrap = ({ intact }: { intact: XPODeliveryReceiptFooterProps['shrinkWrapIntact'] }): JSX.Element => {
  return (
    <Box label={'SHRINK WRAP INTACT'} flex={1}>
      <Pane justifyContent="space-between" width={'60%'}>
        <Checkbox label={'Yes'} selected={intact === 'yes'} />
        <Checkbox label={'No'} selected={intact === 'no'} />
        <Checkbox label={'N/A'} selected={intact === 'na'} />
      </Pane>
    </Box>
  )
}

const Delivered = ({ pieceCount }: { pieceCount: number }): JSX.Element => {
  return (
    <Box label={'DELIVERED'} width={XPODeliveryReceiptTableColumnSize.CHARGES}>
      <Pane alignItems={'center'}>
        <Text bold fontSize={10} textAlign={'center'}>
          {pieceCount}
        </Text>
        <Text textAlign={'right'} width={'100%'}>
          PIECES
        </Text>
      </Pane>
    </Box>
  )
}

const Time = ({ minute, hour }: Pick<XPODeliveryReceiptFooterProps, 'minute' | 'hour'>): JSX.Element => {
  const twelveHour = hour > 12 ? hour - 12 : hour
  const period = hour >= 12 ? 'pm' : 'am'
  return (
    <Box label={'TIME'} width={XPODeliveryReceiptTableColumnSize.CHARGES}>
      <Text bold fontSize={10} textAlign={'center'}>
        {twelveHour}:{minute < 10 ? `0${minute}` : minute}
        {period}
      </Text>
    </Box>
  )
}

const Signature = ({ label, src }: { label: string; src?: string | undefined }): JSX.Element => {
  return (
    <Box label={label} width={XPODeliveryReceiptTableColumnSize.RATE + XPODeliveryReceiptTableColumnSize.WEIGHT}>
      <Pane width={'100%'} position="relative">
        {src ? (
          <Image
            src={src}
            style={{
              width: '80%',
              height: 25,
              position: 'relative',
              top: 0,
              left: 0,
            }}
          />
        ) : null}
      </Pane>
    </Box>
  )
}

const DriverSignature = ({ label, name }: { label: string; name?: string | undefined }): JSX.Element => {
  return (
    <Box label={label} width={XPODeliveryReceiptTableColumnSize.RATE + XPODeliveryReceiptTableColumnSize.WEIGHT}>
      <Pane width={'100%'} position="relative">
        {name ? (
          <Pane position="absolute" top={-3} left={5}>
            <Text font={Fonts.Signature.Normal} fontSize={16}>
              {name}
            </Text>
          </Pane>
        ) : null}
      </Pane>
    </Box>
  )
}

const Date = ({ day, month, year }: Pick<XPODeliveryReceiptFooterProps, 'day' | 'month' | 'year'>): JSX.Element => {
  return (
    <Box label={'DATE'} width={XPODeliveryReceiptTableColumnSize.CHARGES} last>
      <Text bold fontSize={10} textAlign={'center'}>
        {month} / {day} / {year}
      </Text>
    </Box>
  )
}

const ConsigneeName = ({ consigneeName }: Pick<XPODeliveryReceiptFooterProps, 'consigneeName'>): JSX.Element => {
  return (
    <Box
      label={'PRINT CONSIGNEE NAME'}
      width={XPODeliveryReceiptTableColumnSize.RATE + XPODeliveryReceiptTableColumnSize.WEIGHT}
    >
      <Text bold fontSize={10} textAlign={'center'}>
        {consigneeName}
      </Text>
    </Box>
  )
}

const Accessorials = (props: XPODeliveryReceiptFooterProps['accessorials']): JSX.Element => {
  const { inside, residential, liftgate, construction } = props ?? {}
  return (
    <Pane flex={1} borderRight={BORDER} paddingHorizontal={4} height={'100%'} paddingVertical={2} paddingBottom={4}>
      <Pane direction={'column'} flex={1} height={'100%'} justifyContent="space-between">
        <Checkbox label={`INSIDE\nDELIVERY`} selected={inside} bold />
        <Checkbox label={`RESIDENTIAL\nDELIVERY`} selected={residential} bold />
      </Pane>
      <Pane direction={'column'} flex={1} height={'100%'} justifyContent="space-between">
        <Checkbox label={`LIFTGATE\nSERVICE`} selected={liftgate} bold />
        <Checkbox label={`CONSTRUCTION\n/UTIL`} selected={construction} bold />
      </Pane>
    </Pane>
  )
}

const Checkbox = ({ label, selected, bold }: { label: string; selected?: boolean; bold?: boolean }): JSX.Element => (
  <Pane alignItems="center">
    <Pane width={6} height={6} backgroundColor={selected ? '#000' : '#ffffff'} border={BORDER} marginRight={2} />
    <Text bold={bold} lineHeight={0.9}>
      {label}
    </Text>
  </Pane>
)
