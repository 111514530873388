import { Column } from '../Pane'
import { Text } from '../Text'

export const BillOfLadingProNumber = (): JSX.Element => (
  <Column border={'12px solid #000000'} padding={12} width={'100%'} alignItems="center">
    <Text bold fontSize={16} color={'#000000'}>
      PLACE PRO # LABEL HERE
    </Text>
    <Text fontSize={12} color={'#000000'}>
      LTL Carriers Only
    </Text>
    <Text fontSize={12} color={'#000000'}>
      Not required for local or truckload
    </Text>
  </Column>
)
