import React, { useState } from 'react'
import styled from 'styled-components'
import { Pane } from 'evergreen-ui'
import { Switch, Route } from 'react-router-dom'
import { CornerDialog } from 'evergreen-ui'
import { Navigator } from '../components/Navigator'
import { DocumentTopBar } from '../components/DocumentTopBar'
import { DocumentRouter } from '../router/DocumentRouter'
import { HomePage } from '../components/HomePage'
import { DEFAULT_DOCUMENT_PATH } from '../router/paths'

export const Dashboard = (): JSX.Element => {
  const [dialogShown, setDialogShown] = useState<boolean>(true)
  return (
    <>
      <NavigatorContainer>
        <Navigator />
      </NavigatorContainer>
      <MainContainer>
        <Switch>
          <Route exact path={'/'}>
            <HomePage />
          </Route>
          <Route path={DEFAULT_DOCUMENT_PATH}>
            <CornerDialog
              title="Welcome to the Mothership document explorer!"
              isShown={dialogShown}
              onCloseComplete={() => setDialogShown(false)}
              confirmLabel={'I understand'}
              hasClose={false}
              hasCancel={false}
            >
              When viewing or modifying documents please remember that in PDF view you need to refresh the browser.
            </CornerDialog>
            <TopBarContainer>
              <DocumentTopBar />
            </TopBarContainer>
            <DocumentRouter />
          </Route>
        </Switch>
      </MainContainer>
    </>
  )
}

const NavigatorContainer = styled(Pane).attrs({
  height: '100%',
  width: 320,
})``

const MainContainer = styled(Pane).attrs({
  height: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
})``

const TopBarContainer = styled(Pane).attrs({
  width: '100%',
  height: 48,
})``
