import { Image, Text as RenderText } from '@react-pdf/renderer'
import { createCode39Barcode } from '../lib/barcode'
import { Fonts } from '../style'
import { Pane } from './Pane'
import { Text } from './Text'
import { XPOLogo } from './XPOLogo'

interface XPODeliveryReceiptHeaderProps {
  proNumber: string
}

const BORDER = '1px solid black'

export const XPODeliveryReceiptHeader = (props: XPODeliveryReceiptHeaderProps): JSX.Element => {
  const { proNumber } = props
  return (
    <Pane
      paddingVertical={4}
      paddingHorizontal={8}
      border={BORDER}
      alignItems="center"
      justifyContent="flex-start"
      backgroundColor={'white'}
      fixed
    >
      <Branding />
      <CNWYBarcode />
      <Legal />
      <Page />
      <ProNumberBarcode proNumber={proNumber} />
      <ProNumberText proNumber={proNumber} />
    </Pane>
  )
}

const Branding = (): JSX.Element => (
  <Pane direction="column" alignItems="center">
    <XPOLogo width={90} />
    <Text paddingTop={2}>www.xpo.com</Text>
  </Pane>
)

const CNWYBarcode = (): JSX.Element => (
  <Pane direction="column" alignItems="center" paddingLeft={8}>
    <Image style={{ width: 90, height: 18 }} src={createCode39Barcode('CNWY')} />
    <Text fontSize={8}>CNWY</Text>
  </Pane>
)

const Legal = (): JSX.Element => (
  <Pane direction="column" alignItems="center" justifyContent="center">
    <Text bold>DELIVERY RECEIPT</Text>
    <Text bold paddingTop={2}>
      XPO CUSTOMER COPY
    </Text>
  </Pane>
)

const Page = (): JSX.Element => (
  <Pane direction="column" alignItems="center" justifyContent="center" paddingLeft={12}>
    <Text bold>PAGE</Text>
    <RenderText
      style={{
        fontSize: '7px',
        paddingTop: '2px',
        minHeight: '12px',
        ...Fonts.HelveticaNeue.Bold,
      }}
      fixed
      render={({ pageNumber, totalPages }) => `${pageNumber} OF ${totalPages}`}
    />
    {/* <Text bold paddingTop={2}>
      {props.page} OF {props.totalPages}
    </Text> */}
  </Pane>
)

const ProNumberBarcode = ({ proNumber }: Pick<XPODeliveryReceiptHeaderProps, 'proNumber'>): JSX.Element => (
  <Pane paddingLeft={8}>
    <Image style={{ width: 172, height: 22 }} src={createCode39Barcode(proNumber)} />
  </Pane>
)

const ProNumberText = ({ proNumber }: Pick<XPODeliveryReceiptHeaderProps, 'proNumber'>): JSX.Element => (
  <Pane direction="column" alignItems="center" justifyContent="center">
    <Text bold fontSize={8}>
      PRO NUMBER
    </Text>
    <Text bold fontSize={8} paddingTop={2}>
      {proNumber}
    </Text>
  </Pane>
)
