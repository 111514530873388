import { TableDocumentRenderValues } from '@mothership/document-types'

export const TableMockData: TableDocumentRenderValues<Record<string, string>> = [
  {
    testOne: 'First test.',
    testTwo: 'First test too.',
  },
  {
    testOne: 'Second test.',
    testTwo: 'Second test too.',
  },
  {
    testOne: 'Third test.',
    testTwo: 'Third test too.',
  },
]
