import { defaultTheme, Pane, ThemeProvider } from 'evergreen-ui'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import styled from 'styled-components'
import { Reset } from 'styled-reset'
import { AuthRouter } from './router/AuthRouter'

export const App = (): JSX.Element => {
  return (
    <React.Fragment>
      <Reset />
      <ThemeProvider value={defaultTheme}>
        <Container>
          <BrowserRouter>
            <RouterContainer>
              <AuthRouter />
            </RouterContainer>
          </BrowserRouter>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  )
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
`

const RouterContainer = styled(Pane).attrs({
  height: '100%',
  width: '100%',
  display: 'flex',
})``
