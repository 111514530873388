import { InvoiceDocumentGenerationData } from '@mothership/document-types'
import { CreditType, InvoiceDtoStatus, PaymentStatus } from '@mothership/finance/dist/api'
import { ShipmentInvoiceMetadata } from '@mothership/shipment-types/accounting'
import { WaypointType } from '@mothership/shipment-types/models'
import moment from 'moment-timezone'
import { getDescriptionForCredit } from '../utils/credit'
import { centsToCurrencyString } from '../utils/currency'
import { freightTypeAndQuantityString } from '../utils/freight'
import { capitalizeFirstLetter } from '../utils/string'
import { Column, Pane } from './Pane'
import { Text, TextProps } from './Text'

const BORDER = '1px solid #e2e2e2'

const NON_REFUND_CREDIT_TYPES = [
  CreditType.CreditNoteAllocation,
  CreditType.Other,
  CreditType.OtherCustomerCredit,
  CreditType.Overpayment,
  CreditType.Promotional,
  CreditType.Referral,
  CreditType.Support,
  CreditType.Uncollectible,
]

const Label = (props: TextProps): JSX.Element => Text({ bold: true, fontSize: 9, paddingBottom: 4, ...props })

export const AccountingInvoiceBreakdown = ({
  invoice,
  shipmentInvoiceMetadata,
}: {
  invoice: InvoiceDocumentGenerationData
  shipmentInvoiceMetadata: Partial<ShipmentInvoiceMetadata> | null
}): JSX.Element => {
  const pickupWaypoint = shipmentInvoiceMetadata?.waypoints?.find((waypoint) => waypoint.type === WaypointType.Pickup)
  const deliveryWaypoint = shipmentInvoiceMetadata?.waypoints?.find(
    (waypoint) => waypoint.type === WaypointType.Delivery,
  )

  const freightItemsStrings: string[] =
    shipmentInvoiceMetadata?.freight?.map(
      (freight) => `${freightTypeAndQuantityString(freight.type, freight.quantity)} - ${freight.weight}lbs each`,
    ) || []

  const creditNotes = (invoice.credits ?? []).filter((credit) => NON_REFUND_CREDIT_TYPES.includes(credit.type))
  const refunds = (invoice.credits ?? []).filter((credit) => credit.type === CreditType.Refund)
  const successfulPayment = (invoice.payments ?? []).find((payment) => payment.status === PaymentStatus.Succeeded)

  let pickupAddress: string | undefined, deliveryAddress: string | undefined

  if (pickupWaypoint) {
    pickupAddress = `${pickupWaypoint.streetAddress1}, ${pickupWaypoint.city}, ${pickupWaypoint.state} ${pickupWaypoint.zipCode}`
  }

  if (deliveryWaypoint) {
    deliveryAddress =
      `${deliveryWaypoint.streetAddress1}, ${deliveryWaypoint.city}, ` +
      `${deliveryWaypoint.state} ${deliveryWaypoint.zipCode}`
  }

  return (
    <>
      <Pane width={'100%'} paddingTop={8} paddingBottom={8} borderBottom={BORDER}>
        <Text bold flex={1}>
          Description
        </Text>
        <Text bold width={130}>
          Transaction breakdown
        </Text>
        <Text bold width={100} textAlign="right">
          Amount due
        </Text>
      </Pane>
      <Pane justifyContent="space-between" width={'100%'} flex={1} paddingTop={8}>
        <Pane direction="column" width={230}>
          {pickupWaypoint || deliveryWaypoint ? (
            <Pane direction="column" paddingBottom={8} borderBottom={BORDER}>
              {pickupWaypoint ? (
                <Pane direction="column">
                  <Label>Pick-up</Label>
                  <Text bold>{pickupWaypoint.locationName}</Text>
                  <Text>{pickupAddress}</Text>
                </Pane>
              ) : null}
              {deliveryWaypoint ? (
                <Pane direction="column" paddingTop={8}>
                  <Label>Delivery</Label>
                  <Text bold>{deliveryWaypoint.locationName}</Text>
                  <Text>{deliveryAddress}</Text>
                </Pane>
              ) : null}
            </Pane>
          ) : null}

          <Pane direction="column" paddingTop={8} paddingBottom={8} borderBottom={BORDER}>
            <Label>Cargo</Label>
            {freightItemsStrings.map((freight, index) => (
              <Text paddingTop={2} key={index}>
                {freight}
              </Text>
            ))}
          </Pane>
          <Pane paddingTop={8} paddingBottom={8} borderBottom={BORDER}>
            <Text bold>Booked by </Text>
            <Text>{shipmentInvoiceMetadata?.shipperUserName ?? invoice.contact.organizationName}</Text>
          </Pane>

          {shipmentInvoiceMetadata?.routeReferenceNumber ? (
            <Pane paddingTop={8} paddingBottom={8} borderBottom={BORDER}>
              <Text bold>Route #: </Text>
              <Text>{shipmentInvoiceMetadata.routeReferenceNumber}</Text>
            </Pane>
          ) : null}
          {shipmentInvoiceMetadata?.referenceNumber ? (
            <Pane paddingTop={8} paddingBottom={8} borderBottom={BORDER}>
              <Text bold>Shipment #: </Text>
              <Text>{shipmentInvoiceMetadata.referenceNumber}</Text>
            </Pane>
          ) : null}
          {shipmentInvoiceMetadata?.purchaseOrderNumber ? (
            <Pane paddingTop={8} paddingBottom={8} borderBottom={BORDER}>
              <Text bold>Purchase order #: </Text>
              <Text>{shipmentInvoiceMetadata.purchaseOrderNumber}</Text>
            </Pane>
          ) : null}
          {pickupWaypoint?.shipperReferenceNumber ? (
            <Pane paddingTop={8} paddingBottom={8} borderBottom={BORDER}>
              <Text bold>Pick-up reference #: </Text>
              <Text>{pickupWaypoint.shipperReferenceNumber}</Text>
            </Pane>
          ) : null}
          {pickupWaypoint?.shipperNotes ? (
            <Pane paddingTop={8} paddingBottom={8} borderBottom={BORDER}>
              <Text bold>Pick-up notes: </Text>
              <Text>{pickupWaypoint.shipperNotes}</Text>
            </Pane>
          ) : null}
          {deliveryWaypoint?.shipperReferenceNumber ? (
            <Pane paddingTop={8} paddingBottom={8} borderBottom={BORDER}>
              <Text bold>Delivery reference #: </Text>
              <Text>{deliveryWaypoint.shipperReferenceNumber}</Text>
            </Pane>
          ) : null}
          {deliveryWaypoint?.shipperNotes ? (
            <Pane paddingTop={8} paddingBottom={8} borderBottom={BORDER}>
              <Text bold>Delivery notes: </Text>
              <Text>{deliveryWaypoint.shipperNotes}</Text>
            </Pane>
          ) : null}
        </Pane>
        <Pane direction="column" width={230}>
          {invoice.lineItems.map((lineItem, index) => (
            <Pane justifyContent="space-between" width={'100%'} key={lineItem.id ?? index}>
              <Text>{lineItem.description ? lineItem.description : 'Service charge'}</Text>
              <Text bold>{centsToCurrencyString(lineItem.totalAmountCents)}</Text>
            </Pane>
          ))}
          <Pane
            justifyContent="space-between"
            width={'100%'}
            borderTop={BORDER}
            borderBottom={BORDER}
            paddingTop={8}
            paddingBottom={8}
            marginTop={8}
          >
            <Text bold>Subtotal</Text>
            <Text bold>{centsToCurrencyString(invoice.breakdown.subtotalCents)}</Text>
          </Pane>

          {/** CREDIT NOTES */}
          {creditNotes.length > 0 ? (
            <>
              <Text bold paddingTop={8} paddingBottom={8}>
                Adjustments
              </Text>
              {creditNotes.map((credit, index) => (
                <Pane justifyContent="space-between" width={'100%'} key={credit.id ?? index}>
                  <Text>{getDescriptionForCredit(credit)}</Text>
                  <Text bold>{centsToCurrencyString(-1 * credit.amountCents)}</Text>
                </Pane>
              ))}
            </>
          ) : null}

          {/** PAYMENTS */}
          {invoice.payments.length > 0 ? (
            <Column borderTop={BORDER} width={'100%'} marginTop={8}>
              <Text bold paddingTop={8} paddingBottom={8}>
                Payments
              </Text>
              {invoice.payments.map((payment, index) => (
                <Pane justifyContent="space-between" width={'100%'} key={payment.id ?? index}>
                  <Text>
                    {payment.brand.length ? capitalizeFirstLetter(payment.brand) : ''} {payment.last4} -{' '}
                    {capitalizeFirstLetter(payment.status)}
                  </Text>
                  <Text bold={payment.status !== PaymentStatus.Failed}>
                    {centsToCurrencyString(-1 * payment.amountCents)}
                  </Text>
                </Pane>
              ))}
            </Column>
          ) : null}

          {/** REFUNDS */}
          {refunds.length > 0 ? (
            <Column borderTop={BORDER} width={'100%'} marginTop={8}>
              <Text bold paddingTop={8} paddingBottom={8}>
                Refunds
              </Text>
              {refunds.map((refund, index) => (
                <Pane justifyContent="space-between" width={'100%'} key={refund.id ?? index}>
                  <Text>
                    {successfulPayment?.brand.length ? capitalizeFirstLetter(successfulPayment?.brand) + ' ' : ''}
                    {successfulPayment?.last4 ? successfulPayment?.last4 + ' - ' : ''}
                    {moment(refund.createdAt).utc().format('ddd MMM D, YYYY')}
                    {'\n'}
                    {refund.description ?? 'No description provided.'}
                  </Text>
                  <Text bold>{'+' + centsToCurrencyString(refund.amountCents)}</Text>
                </Pane>
              ))}
            </Column>
          ) : null}

          <Pane
            direction="column"
            justifyContent="flex-end"
            width={'100%'}
            borderTop={BORDER}
            marginTop={8}
            paddingTop={17}
            paddingBottom={17}
            borderBottom={BORDER}
          >
            <Text textAlign="right">
              {'Amount due' +
                (invoice.status === InvoiceDtoStatus.Paid
                  ? ''
                  : ` by ${moment(invoice.dueBy).utc().format('ddd MMM D, YYYY')}`)}
            </Text>
            <Text bold textAlign="right" fontSize={18} paddingTop={8}>
              {centsToCurrencyString(invoice.remainingAmountDueCents)}
            </Text>
          </Pane>
        </Pane>
      </Pane>
    </>
  )
}
