import { ShippingLabelParams } from '@mothership/document-types'
import { Image, Page, StyleSheet } from '@react-pdf/renderer'
import { Column, Pane, Row } from '../components/Pane'
import { PDFWrapper } from '../components/PDFWrapper'
import { Text } from '../components/Text'
import { Scissors } from '../icons/Scissors'
import { createCode39Barcode } from '../lib/barcode'
import { DocumentType } from './DocumentType'

const styles = StyleSheet.create({
  page: {
    padding: 24,
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontSize: 7,
  },
})

const SMALL_TEXT = 14
const LARGE_TEXT = 20
const PADDING = 17

/* NOTE [jdao]: As of Feb 2023, we do not generate shipping labels separately from the BOL. */
export const ShippingLabel: DocumentType<ShippingLabelParams> = ({
  renderValues,
}: {
  renderValues: ShippingLabelParams
}): JSX.Element => {
  return <PDFWrapper>{generateShippingLabelPages(renderValues)}</PDFWrapper>
}

export const generateShippingLabelPages = (renderValues: ShippingLabelParams): JSX.Element[] => {
  const { numberOfLabels } = renderValues
  const pages = []
  let pageCount = 1

  /* Two labels per page */
  for (let count = 1; count <= numberOfLabels; count += 2) {
    pages.push(
      <Page key={`page_${pageCount}`} size="LETTER" style={styles.page} wrap={false}>
        <ShippingLabelContent {...renderValues} currentLabel={count} />
        {count < numberOfLabels && (
          <>
            <CutAlongLine />
            <ShippingLabelContent {...renderValues} currentLabel={count + 1} />
          </>
        )}
      </Page>,
    )
    pageCount += 1
  }

  return pages
}

const ShippingLabelContent = (props: ShippingLabelParams & { currentLabel: number }) => {
  const { billOfLadingNumber, currentLabel, deliveryAddress, integratedCarrierName, pickupNumber, numberOfLabels } =
    props
  return (
    <Column flex={1}>
      <Row justifyContent="space-between" width={'100%'}>
        <Text fontSize={SMALL_TEXT}>BOL #</Text>
        {integratedCarrierName ? <Text fontSize={SMALL_TEXT}>CARRIER:</Text> : <></>}
        <Text fontSize={SMALL_TEXT}>Pickup Number #:</Text>
      </Row>
      <Row justifyContent="space-between" width={'100%'} paddingTop={5}>
        <Text bold fontSize={LARGE_TEXT}>
          {billOfLadingNumber}
        </Text>
        {integratedCarrierName ? (
          <Text bold fontSize={LARGE_TEXT}>
            {integratedCarrierName}
          </Text>
        ) : (
          <></>
        )}
        <Text bold fontSize={LARGE_TEXT}>
          {pickupNumber}
        </Text>
      </Row>
      <Row
        justifyContent="flex-end"
        width={'100%'}
        borderBottom={'2px solid #000000'}
        marginTop={8}
        paddingBottom={PADDING}
      >
        <Image src={createCode39Barcode(pickupNumber)} style={{ width: 150, height: 35 }} />
      </Row>
      <Column borderBottom={'2px solid #000000'} width={'100%'} paddingBottom={PADDING}>
        <Text fontSize={SMALL_TEXT} paddingTop={PADDING}>
          CONSIGNEE / DESTINATION:
        </Text>
        <Text fontSize={LARGE_TEXT} bold paddingTop={8}>
          {deliveryAddress.name}
        </Text>
        <Text fontSize={LARGE_TEXT} bold>
          {deliveryAddress.streetAddress1}
        </Text>
        {deliveryAddress.streetAddress2 ? (
          <Text fontSize={LARGE_TEXT} bold>
            {deliveryAddress.streetAddress2}
          </Text>
        ) : (
          <></>
        )}
        <Text fontSize={LARGE_TEXT} bold>
          {deliveryAddress.city}, {deliveryAddress.state} {deliveryAddress.zip}
        </Text>
      </Column>
      <Column flex={1} justifyContent={'flex-start'} alignItems="center" width={'100%'} paddingTop={30}>
        <Text fontSize={LARGE_TEXT} bold>
          Count: {currentLabel} of {numberOfLabels}
        </Text>
      </Column>
    </Column>
  )
}

const DashedLine = ({ left }: { left?: boolean }) => (
  <Pane flex={1} paddingLeft={left ? undefined : 8} paddingRight={left ? 8 : undefined}>
    <Pane borderBottom={'2px dashed #000000'} width={'100%'} height={2}>
      <Text> </Text>
    </Pane>
  </Pane>
)

const CutAlongLine = () => {
  return (
    <Pane width={'100%'} justifyContent="center" alignItems="center" flex={0.25}>
      <DashedLine left />
      <Scissors />
      <Text paddingLeft={8} paddingRight={8} fontSize={SMALL_TEXT} bold>
        cut and tape one to each pallet
      </Text>
      <Scissors />
      <DashedLine />
    </Pane>
  )
}
