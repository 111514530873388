import { Column, Row } from '../Pane'
import { Text } from '../Text'
import { BOL_DEFAULT_BORDER, BOL_DEFAULT_HEADER_PADDING, BOL_DEFAULT_MARGIN } from './BillOfLadingDefaults'

interface BillOfLadingCarrierDetailsProps {
  bolNumber?: string
  carrierName?: string
  pickupNumber?: string
  quoteId?: string
}
type DetailRow = {
  label: string
  value?: string
}

export const BillOfLadingCarrierDetails = ({
  bolNumber,
  carrierName,
  pickupNumber,
  quoteId,
}: BillOfLadingCarrierDetailsProps): JSX.Element => {
  const detailRows: DetailRow[] = [
    { label: 'Carrier Name', value: carrierName },
    { label: 'Pick-Up No.', value: pickupNumber },
    { label: 'BOL No.', value: bolNumber },
  ]

  // NOTE [jdao]: Special Case for Forward Air
  // Forward Air does not use Quote IDs for their shipments, so instead,
  // We will display our account number for them.
  if (carrierName === 'Forward Air') {
    detailRows.splice(2, 0, { label: 'Account No.', value: '2000103' })
  } else {
    detailRows.splice(2, 0, { label: 'Quote No.', value: quoteId })
  }

  return (
    <Column width={'100%'} border={BOL_DEFAULT_BORDER} marginTop={BOL_DEFAULT_MARGIN}>
      {detailRows.map((row, index) => (
        <Row key={row.label} width={'100%'} borderTop={index > 0 ? BOL_DEFAULT_BORDER : undefined}>
          <Text
            backgroundColor={'#eeeeee'}
            bold
            borderRight={BOL_DEFAULT_BORDER}
            width={69}
            textAlign="right"
            {...BOL_DEFAULT_HEADER_PADDING}
          >
            {row.label}
          </Text>
          <Text {...BOL_DEFAULT_HEADER_PADDING}>{row.value || ''}</Text>
        </Row>
      ))}
    </Column>
  )
}
