import { generateMockXPODeliveryReceiptGenerationData } from '@mothership/document-types'
import { PDFViewer } from '@react-pdf/renderer'
import Timeout from 'await-timeout'
import { Spinner } from 'evergreen-ui'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

// Document Templates
import {
  BillOfLading,
  CarrierEarningReport,
  Invoice,
  RateConfirmation,
  ShippingLabel,
  Table,
  XPODeliveryReceipt,
} from '../templates/documents'

// Mock Data
import { DocumentTemplate } from '@mothership/document-types'
import { useDocumentMatch } from '../hooks/useDocumentMatch'
import { Invoice as InvoiceMockData } from '../templates/documents/mock/Accounting.data'
import { BillOfLading as BillOfLadingMockData } from '../templates/documents/mock/BillOfLading.data'
import { CarrierEarnings } from '../templates/documents/mock/CarrierEarningReport.data'
import { RateConfirmation as RateConfirmationMockData } from '../templates/documents/mock/RateConfirmation.data'
import { ShippingLabel as ShippingLabelMockData } from '../templates/documents/mock/ShippingLabel.data'
import { TableMockData } from '../templates/documents/mock/Table.data'
import { DocumentViewingType } from './paths'

const renderTemplate = (template?: DocumentTemplate): JSX.Element | null => {
  switch (template) {
    // Accounting Documents
    case DocumentTemplate.Invoice:
      return <Invoice renderValues={InvoiceMockData} />

    // Shipment Documents
    case DocumentTemplate.BillOfLading:
      return <BillOfLading renderValues={BillOfLadingMockData} copies={2} />
    case DocumentTemplate.RateConfirmation:
      return <RateConfirmation renderValues={RateConfirmationMockData} />
    case DocumentTemplate.ShippingLabel:
      return <ShippingLabel renderValues={ShippingLabelMockData} />
    case DocumentTemplate.CarrierEarningsReport:
      return <CarrierEarningReport renderValues={CarrierEarnings} />

    // Delivery Receipts
    case DocumentTemplate.XPODeliveryReceipt:
      return (
        <XPODeliveryReceipt
          renderValues={generateMockXPODeliveryReceiptGenerationData({
            commoditiesCount: 8,
            otherReferenceNumberCount: 10,
            appointment: true,
            remarks: true,
          })}
        />
      )

    // Components
    case DocumentTemplate.Table:
      return <Table renderValues={TableMockData} />

    default:
      // FOR SAFETY but shouldn't render this technically
      return null
  }
}

export const DocumentRouter = (): JSX.Element | null => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [currentViewingTemplate, setCurrentViewingTemplate] = useState<DocumentTemplate | undefined>(undefined)
  const { viewingType, template } = useDocumentMatch()

  const onTemplateChange = async (): Promise<void> => {
    setLoading(true)
    await Timeout.set(300)
    setCurrentViewingTemplate(template)
    setLoading(false)
  }

  useEffect(() => {
    onTemplateChange()
  }, [template])

  const componentTemplate = renderTemplate(currentViewingTemplate)

  if (isLoading || !componentTemplate) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    )
  }

  return viewingType === DocumentViewingType.PDF ? (
    <PDFView>{componentTemplate}</PDFView>
  ) : (
    <WebView>
      <WebDocument>{componentTemplate}</WebDocument>
    </WebView>
  )
}

const WebView = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #dddddd;
  overflow: hidden;
`

const WebDocument = styled.div`
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  margin-top: 30px;
  margin-left: 30px;
  background-color: white;
  overflow-y: scroll;
`

const PDFView = styled(PDFViewer)`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  iframe {
    width: 100%;
    height: 100%;
  }
`

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`
