// Google

export const config = {
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || missing('REACT_APP_GOOGLE_CLIENT_ID'),
  googleLoginRedirectURI:
    process.env.REACT_APP_GOOGLE_AUTH_LOGIN_REDIRECT_URI || missing('REACT_APP_GOOGLE_AUTH_LOGIN_REDIRECT_URI'),
  authLoginUrl: process.env.REACT_APP_BASE_API_AUTH_URL || missing('REACT_APP_BASE_API_AUTH_URL'),

  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || missing('REACT_APP_FIREBASE_API_KEY'),
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || missing('REACT_APP_FIREBASE_AUTH_DOMAIN'),
    databaseUrl: process.env.REACT_APP_FIREBASE_DATABASE_URL || missing('REACT_APP_FIREBASE_DATABASE_URL'),
    projectID: process.env.REACT_APP_FIREBASE_PROJECT_ID || missing('REACT_APP_FIREBASE_PROJECT_ID'),
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || missing('REACT_APP_FIREBASE_STORAGE_BUCKET'),
  },

  axel: {
    baseUrl: process.env.REACT_APP_AXEL_API_BASE_URL || missing('REACT_APP_AXEL_API_BASE_URL'),
  },
}

// TODO - Firebase

function missing(name: string): never {
  const message = `Missing env var: ${name}`
  throw new Error(message)
}
