import { FreightType } from '@mothership/shipment-types/models'

export const freightTypeAndQuantityString = (type: FreightType, quantity: number): string => {
  const pluralize = quantity > 1 ? (type === 'Box' ? 'es' : 's') : ''
  switch (type) {
    case FreightType.Pallet:
      return `${quantity} pallet${pluralize}`
    case FreightType.Box:
      return `${quantity} box${pluralize}`
    case FreightType.Crate:
      return `${quantity} crate${pluralize}`
    case FreightType.LooseItem:
      return `${quantity} loose item${pluralize}`
    case FreightType.Container:
      return `${quantity} container${pluralize}`
    default:
      return `${quantity} ${type}(s)`
  }
}
