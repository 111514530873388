import { RateConfirmationDocumentGenerationData } from '@mothership/document-types'
import { shipmentFixtureGenerators } from '@mothership/shipment-types'

export const RateConfirmation: RateConfirmationDocumentGenerationData = {
  payoutAmountCents: 385200,
  driver: {
    firstName: 'Juan',
    lastName: 'Hernandez',
    email: 'juan.hernandez@gmail.com',
    phoneNumber: '+13104422399',
    dotNumber: '1234567',
  },
  shipment: shipmentFixtureGenerators.shipment(),
}
