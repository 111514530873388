import { Style } from '@react-pdf/types'
import { Pane } from './Pane'
import { Text } from './Text'
import { XPODeliveryReceiptTableColumnSize } from './XPODeliveryReceiptTable'

interface Commodity {
  piecesCount: number
  description: string
  weightLbs: number
  charge?: string
}

interface Accessorial {
  description: string
  code: string
}
interface XPODeliveryReceiptCommoditiesAndRemarksProps {
  commodities?: Commodity[]
  accessorials?: Accessorial[]
  totalPieces: number
  totalWeight: number
  finalCharges?: string
  poNumbers?: string[]
  otherReferenceNumbers?: string[]
  remarks?: string[]
}

export const XPODeliveryReceiptCommoditiesAndRemarks = (
  props: XPODeliveryReceiptCommoditiesAndRemarksProps,
): JSX.Element => {
  const {
    commodities = [],
    accessorials = [],
    totalPieces,
    totalWeight,
    finalCharges,
    poNumbers,
    otherReferenceNumbers,
    remarks = [],
  } = props
  return (
    <Pane direction="column" flex={1} width={'100%'}>
      {commodities.map((commodity, index) => (
        <CommodityLine commodity={commodity} key={commodity.description + index} />
      ))}
      {accessorials.map((accessorial, index) => (
        <AccessorialLine accessorial={accessorial} key={accessorial.description + index} />
      ))}
      <TotalLine totalPieces={totalPieces} totalWeight={totalWeight} finalCharges={finalCharges} />
      <PONumbers poNumbers={poNumbers} />
      <OtherReferenceNumbers otherReferenceNumbers={otherReferenceNumbers} />
      {remarks.length ?? 0 > 0 ? <Remarks remarks={`${remarks.join('\n')}`} /> : undefined}
    </Pane>
  )
}

export const XPODeliveryReceiptCommoditiesAndRemarksHeader = (): JSX.Element => (
  <Pane borderBottom={'1px solid black'} width={'100%'} fixed={true}>
    <HeaderCell label="PCS" width={XPODeliveryReceiptTableColumnSize.PCS} />
    <HeaderCell label="HM" width={XPODeliveryReceiptTableColumnSize.HM} />
    <HeaderCell label="DESCRIPTION OF ARTICLES AND REMARKS" fill />
    <HeaderCell label="WEIGHT (LBS.)" width={XPODeliveryReceiptTableColumnSize.WEIGHT} />
    <HeaderCell label="RATE" textAlign="right" width={XPODeliveryReceiptTableColumnSize.RATE} />
    <HeaderCell label="CHARGES" textAlign="right" width={XPODeliveryReceiptTableColumnSize.CHARGES} />
  </Pane>
)

const CommodityLine = ({ commodity }: { commodity: Commodity }): JSX.Element => {
  return (
    <Pane width={'100%'}>
      <ValueCell value={`${commodity.piecesCount}`} width={XPODeliveryReceiptTableColumnSize.PCS} />
      <ValueCell value=" " width={XPODeliveryReceiptTableColumnSize.HM} />
      <ValueCell value={commodity.description} fill textAlign="left" />
      <ValueCell value={`${commodity.weightLbs}`} width={XPODeliveryReceiptTableColumnSize.WEIGHT} textAlign="right" />
      <ValueCell value=" " textAlign="right" width={XPODeliveryReceiptTableColumnSize.RATE} />
      <ValueCell value={commodity.charge} textAlign="left" width={XPODeliveryReceiptTableColumnSize.CHARGES} />
    </Pane>
  )
}

const AccessorialLine = ({ accessorial }: { accessorial: Accessorial }): JSX.Element => {
  return (
    <Pane width={'100%'}>
      <ValueCell width={XPODeliveryReceiptTableColumnSize.PCS} />
      <ValueCell width={XPODeliveryReceiptTableColumnSize.HM} />
      <ValueCell value={accessorial.description} fill textAlign="left" />
      <ValueCell width={XPODeliveryReceiptTableColumnSize.WEIGHT} />
      <ValueCell value=" " textAlign="right" width={XPODeliveryReceiptTableColumnSize.RATE} />
      <ValueCell width={XPODeliveryReceiptTableColumnSize.CHARGES} textAlign="left" />
    </Pane>
  )
}

const TotalLine = ({
  totalPieces,
  totalWeight,
  finalCharges,
}: Pick<XPODeliveryReceiptCommoditiesAndRemarksProps, 'totalPieces' | 'totalWeight' | 'finalCharges'>): JSX.Element => {
  return (
    <Pane width={'100%'}>
      <ValueCell value={`${totalPieces}`} width={XPODeliveryReceiptTableColumnSize.PCS} />
      <ValueCell width={XPODeliveryReceiptTableColumnSize.HM} />
      <ValueCell value={'TOTAL'} fill textAlign="center" />
      <ValueCell value={`${totalWeight}`} width={XPODeliveryReceiptTableColumnSize.WEIGHT} textAlign="right" />
      <ValueCell value=" " width={XPODeliveryReceiptTableColumnSize.RATE} />
      <ValueCell value={finalCharges} width={XPODeliveryReceiptTableColumnSize.CHARGES} textAlign="left" bold />
    </Pane>
  )
}

const PONumbers = ({ poNumbers }: Pick<XPODeliveryReceiptCommoditiesAndRemarksProps, 'poNumbers'>): JSX.Element => {
  return (
    <Pane width={'100%'}>
      <ValueCell width={XPODeliveryReceiptTableColumnSize.PCS} />
      <ValueCell width={XPODeliveryReceiptTableColumnSize.HM} />
      <ValueCell boldLeadText={'PO NUMBERS:'} value={poNumbers?.join(', ')} fill textAlign="left" />
      <ValueCell width={XPODeliveryReceiptTableColumnSize.WEIGHT} />
      <ValueCell width={XPODeliveryReceiptTableColumnSize.RATE} />
      <ValueCell width={XPODeliveryReceiptTableColumnSize.CHARGES} />
    </Pane>
  )
}

const OtherReferenceNumbers = ({
  otherReferenceNumbers,
}: Pick<XPODeliveryReceiptCommoditiesAndRemarksProps, 'otherReferenceNumbers'>): JSX.Element => {
  return (
    <Pane width={'100%'}>
      <ValueCell width={XPODeliveryReceiptTableColumnSize.PCS} />
      <ValueCell width={XPODeliveryReceiptTableColumnSize.HM} />
      <ValueCell
        boldLeadText={'OTHER REFERENCE NUMBERS:'}
        value={otherReferenceNumbers?.join(', ')}
        fill
        textAlign="left"
      />
      <ValueCell width={XPODeliveryReceiptTableColumnSize.WEIGHT} />
      <ValueCell width={XPODeliveryReceiptTableColumnSize.RATE} />
      <ValueCell width={XPODeliveryReceiptTableColumnSize.CHARGES} />
    </Pane>
  )
}

const Remarks = ({ remarks }: { remarks: string }): JSX.Element => {
  return (
    <Pane width={'100%'}>
      <ValueCell width={XPODeliveryReceiptTableColumnSize.PCS} />
      <ValueCell width={XPODeliveryReceiptTableColumnSize.HM} />
      <ValueCell value={remarks} fill textAlign="left" />
      <ValueCell width={XPODeliveryReceiptTableColumnSize.WEIGHT} />
      <ValueCell width={XPODeliveryReceiptTableColumnSize.RATE} />
      <ValueCell width={XPODeliveryReceiptTableColumnSize.CHARGES} />
    </Pane>
  )
}

const HeaderCell = ({
  label,
  fill,
  width,
  textAlign,
}: {
  label: string
  fill?: boolean
  width?: number
  textAlign?: Style['textAlign']
}): JSX.Element => {
  return (
    <Pane flex={fill ? 1 : undefined} width={width} padding={2}>
      <Text bold textAlign={textAlign ?? 'center'}>
        {label}
      </Text>
    </Pane>
  )
}

const ValueCell = ({
  value,
  fill,
  width,
  textAlign,
  bold,
  boldLeadText,
}: {
  value?: string
  fill?: boolean
  width?: number
  textAlign?: Style['textAlign']
  bold?: boolean
  boldLeadText?: string
}): JSX.Element => {
  return (
    <Pane flex={fill ? 1 : undefined} width={width} paddingHorizontal={2} lineHeight={1.5}>
      {boldLeadText && (
        <Text bold paddingRight={2}>
          {boldLeadText}
        </Text>
      )}
      <Text textAlign={textAlign ?? 'center'} bold={bold} flex={1}>
        {value ?? ' '}
      </Text>
    </Pane>
  )
}
