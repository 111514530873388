import { parsePhoneNumber } from 'libphonenumber-js'

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const formatPhoneNumberToNational = (phoneNumber: string): string => {
  try {
    const parsedNumber = parsePhoneNumber(phoneNumber)
    const number = parsedNumber.formatNational()
    return number
  } catch (e) {
    return phoneNumber
  }
}
